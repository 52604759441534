export class GetHost {
  apiUrl
  socketUrl
  assetsUrl
  env = undefined

  constructor () {
    this.env = process.env.REACT_APP_ENV
    this._validate()
  }

  /**
   * @private
   */
  _validate () {
    if (typeof (this.env) !== 'undefined' || this.env !== undefined) {
      this.apiUrl = typeof (process.env[`REACT_APP_${this.env}_API`]) !== 'undefined' ? process.env[`REACT_APP_${this.env}_API`] : null
      this.socketUrl = typeof (process.env[`REACT_APP_${this.env}_SOCKET`]) !== 'undefined' ? process.env[`REACT_APP_${this.env}_SOCKET`] : null
      this.assetsUrl = typeof (process.env[`REACT_APP_${this.env}_ASSETS`]) !== 'undefined' ? process.env[`REACT_APP_${this.env}_ASSETS`] : null
    }
  }

  /**
   * @returns {{socket, api, assets}}
   */
  getUrl () {
    this._validate()
    return {
      api: this.apiUrl,
      socket: this.socketUrl,
      assets: this.assetsUrl,
    }
  }

  /**
   * @param {string} url
   * @returns {string}
   */
  api (url) {
    return [this.getUrl().api, url].join('')
  }

  /**
   * @param {string} url
   * @returns {string}
   */
  assets (url) {
    return [this.getUrl().assets, url].join('')
  }

  /**
   * @param {'ws://', 'wss://'}prefix
   * @param {string} url
   * @returns {string}
   */
  socket (prefix, url) {
    return [prefix, this.getUrl().socket, url].join('')
  }

}