import React, { createContext, createRef, useEffect, useRef, useState } from 'react'
import { AUDIO_ACTION_TYPE, AUDIO_STATUS } from '../constants/audio.constant'
import { useLocation } from 'react-router-dom'

export const AudioCtx = createContext()
const AudioProvider = ({ children }) => {
  const location = useLocation()

  const ref = createRef()
  const [state, setState] = useState({
    status: 'pause',
    volume: 1,
    src: ``,
  })

  useEffect(() => {
    if (ref && ref.current) {
      let audio = ref.current

      function _handleTimeUpdate () {
        if (state.status === AUDIO_STATUS.PLAY) {
        }
      }

      function _handleEnded () {
        setState((prev) => ({ ...prev, status: AUDIO_STATUS.PAUSE }))
      }

      audio.addEventListener('timeupdate', _handleTimeUpdate)
      audio.addEventListener('ended', _handleEnded)

      return () => {
        audio.removeEventListener('timeupdate', _handleTimeUpdate)
        audio.removeEventListener('ended', _handleEnded)
      }
    }

  }, [ref])

  /**
   * @param {'PLAY' | 'CHANGE_VOLUME' | string} type
   * @param {object} payload
   */
  function action ({ type, payload }) {
    let _ = { ...state }
    switch (type) {
      case AUDIO_ACTION_TYPE.PLAY:
        if (typeof (payload) === 'object' && Object.keys(payload).length > 0) {
          Object.entries(payload).map(([key, value]) => {
            Reflect.set(_, key, value)
          })
        }
        break
      case AUDIO_ACTION_TYPE.STOP:
        Reflect.set(_, 'status', AUDIO_STATUS.STOP)
        break
      case AUDIO_ACTION_TYPE.PAUSE:
        Reflect.set(_, 'status', AUDIO_STATUS.PAUSE)
        break
      case AUDIO_ACTION_TYPE.CHANGE_VOLUME:
        Reflect.set(_, 'volume', payload?.volume)
        break
    }
    setState({ ..._ })
  }

  useEffect(() => {
    if (ref && ref.current) {
      let audio = ref.current

      if (state.status === AUDIO_STATUS.PLAY) {
        audio.currentTime = 0
        audio.volume = state.volume
        audio.src = state.src
        audio.play()
      } else {
        audio.pause()
      }
    }
  }, [ref, state])

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.volume = state.volume
    }
  }, [state.volume])

  useEffect(() => {
    if (ref && ref.current) {
      setState((prevState) => ({
        ...prevState,
        status: AUDIO_STATUS.PAUSE,
        src: null,
      }))
    }
  }, [location])

  return (
    <React.Fragment>
      <AudioCtx.Provider
        value={{
          action,
          state,
        }}
      >
        {children}
        <audio
          ref={ref}
          src={state.src}
        />
      </AudioCtx.Provider>
    </React.Fragment>
  )
}

export { AudioProvider }
