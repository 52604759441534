import ApiService from '../api.service'

export class UserSagaService {
  static API = ApiService

  /**
   *
   * @param {object} payload
   * @param {object} payload.params
   * @param {boolean | true | false} payload.params.quiz
   * @param {boolean | true | false} payload.params.showAll
   * @param api
   * @return {Promise<unknown>}
   */
  static patching (payload, api = UserSagaService.API) {
    return new Promise((resolve) => {
      resolve(new api({
        url: `/api/v1/user`,
        body: payload,
      }).patch())
    })
  }
}