export const SUCCESS = (type) => {
  return ['@@YID', type, 'SUCCESS'].join('/')
}
export const FAILURE = (type) => {
  return ['@@YID', type, 'FAILURE'].join('/')
}
export const REQUEST = (type) => {
  return ['@@YID', type, 'REQUEST'].join('/')
}
export const NOTFOUND = (type) => {
  return ['@@YID', type, 'NOTFOUND'].join('/')
}
export const EVENT = (type) => {
  return ['@@YID', type, 'EVENT'].join('/')
}

export const VALIDATE_QS = (type) => {
  return ['@@YID', type, 'VALIDATE_QS'].join('/')
}