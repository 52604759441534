export const QUIZ_TYPES = {
  FILL_BLANK: {
    name: 'Fill In The Blank',
    slug: 'fill-in-the-blank',
  },
  MULTIPLE_CHOICE: {
    name: 'Multiple Choice',
    slug: 'multiple-choice',
  },
  TRUE_FALSE: {
    name: 'True or False',
    slug: 'true-or-false',
  },
  COMPREHENSION: {
    name: 'Comprehension',
    slug: 'comprehension',
  },

}

export const QUIZ_HINTS_SEPARATOR = '__SEPARATOR__'

export const QUIZ_FILL_BLANK_FORM = {
  HINT_VALUE: {
    name: ['data', 'value'], onChange: function (val) {
      return {
        data: {
          value: val,
        },
      }
    },
  }, HINT_TEMPLATE: {
    name: ['data', 'template'], onChange: function (val) {
      return {
        data: {
          template: val,
        },
      }
    },
  },
}
export const QUIZ_MULTIPLE_CHOICE_FORM = {}
export const QUIZ_OPTIONS_COLOR = {
  getColor (index) {
    let _ = [
      {
        background: '#F87171',
        color: 'white',
        textColor: '#FFFFFF',
      },
      {
        background: '#FBBF24',
        color: 'white',
        textColor: '#FFFFFF',
      },
      {
        background: '#2DD4BF',
        color: 'white',
        textColor: '#FFFFFF',
      },
      {
        background: '#38BDF8',
        color: 'white',
        textColor: '#FFFFFF',
      },
      {
        background: '#C084FC',
        color: 'white',
        textColor: '#FFFFFF',
      },
    ]

    return typeof (_[index]) !== 'undefined' ? _[index] : {}
  },
}
export const QUIZ_TRUE_FALSE_COLOR = {
  getColor (index) {
    let _ = [
      {
        background: '#F87171',
        color: 'white',
        textColor: '#FFFFFF',
      },
      {
        background: '#38BDF8',
        color: 'white',
        textColor: '#FFFFFF',
      },
    ]

    return typeof (_[index]) !== 'undefined' ? _[index] : {}
  },
}

export const QUIZ_MEDIA = {
  TYPE: {
    name: 'mediaType', onChange: function (val) {
      return {
        mediaType: val,
      }
    },
  }, MEDIA: {
    name: 'media', onChange: function (val) {
      return {
        media: val,
      }
    },
  },
}

export const QUIZ_CONTENT = {
  CONTENT: {
    name: 'content', onChange: function (val) {
      return {
        content: val,
      }
    },
  },
}

export const QUIZ_FORM = {
  TEMPLATE_TYPE: {
    name: ['quizTypeUuid'], onChange: function (val) {
      return {
        type: val,
      }
    },
  }, ...QUIZ_CONTENT, ...QUIZ_MEDIA,
}