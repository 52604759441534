import { ThemeConfig } from 'antd'

const TOKEN_THEME = {
  token: {
    color: {
      primary: {
        'colorPrimaryBg': '#f4ebfd',
        'colorPrimaryBgHover': '#bae0ff',
        'colorPrimaryBorder': '#91caff',
        'colorPrimaryBorderHover': '#69b1ff',
        'colorPrimaryHover': '#4096ff',
        'colorPrimary': '#075fea',
        'colorInfo': '#0958d9',
        'colorPrimaryActive': '#0958d9',
        'colorPrimaryTextHover': '#4096ff',
        'colorPrimaryText': '#1677ff',
        'colorPrimaryTextActive': '#0958d9',
      },
      success: {
        'colorSuccessBg': '#f6ffed',
        'colorSuccessBgHover': '#d9f7be',
        'colorSuccessBorder': '#b7eb8f',
        'colorSuccessBorderHover': '#95de64',
        'colorSuccessHover': '#95de64',
        'colorSuccess': '#52c41a',
        'colorSuccessActive': '#389e0d',
        'colorSuccessTextHover': '#73d13d',
        'colorSuccessText': '#52c41a',
        'colorSuccessTextActive': '#389e0d',
      },
      warning: {
        'colorWarningBg': '#fffbe6',
        'colorWarningBgHover': '#fff1b8',
        'colorWarningBorder': '#ffe58f',
        'colorWarningBorderHover': '#ffd666',
        'colorWarningHover': '#ffd666',
        'colorWarning': '#faad14',
        'colorWarningActive': '#d48806',
        'colorWarningTextHover': '#ffc53d',
        'colorWarningText': '#faad14',
        'colorWarningTextActive': '#d48806',
      },
      error: {
        'colorErrorBg': '#fff2f0',
        'colorErrorBgHover': '#fff1f0',
        'colorErrorBorder': '#ffccc7',
        'colorErrorBorderHover': '#ffa39e',
        'colorErrorHover': '#ff7875',
        'colorError': '#ff4d4f',
        'colorErrorActive': '#d9363e',
        'colorErrorTextHover': '#ff7875',
        'colorErrorText': '#ff4d4f',
        'colorErrorTextActive': '#d9363e',
      },
      link: {
        'colorLinkHover': '#69b1ff',
        'colorLinkActive': '#0958d9',
      },
      neutral: {
        'colorTextBase': '#000000',
        'colorBgBase': '#ffffff',
        'colorText': '#000000e0',
        'colorTextSecondary': '#000000a7',
        'colorTextTertiary': '#00000074',
        'colorTextQuaternary': '#00000040',
        'colorBorder': '#d9d9d9',
        'colorBorderSecondary': '#f0f0f0',
        'colorFill': '#00000026',
        'colorFillSecondary': '#00000010',
        'colorFillTertiary': '#0000000b',
        'colorFillQuaternary': '#00000004',
        'colorBgContainer': '#ffffff',
        'colorBgElevated': '#ffffff',
        'colorBgLayout': '#f5f5f5',
        'colorBgSpotlight': '#000000da',
        'colorBgMask': '#00000072',
      },
    },
    size: {
      fontSize: 14,
      fontSizeSM: 14,
      fontSizeLG: 14,
      fontSizeXL: 20,
      fontSizeHeading1: 32,
      fontSizeHeading2: 26,
      fontSizeHeading3: 20,
      fontSizeHeading4: 16,
      fontSizeHeading5: 14,
      lineHeight: 1.6666666666666667,
      lineHeightSM: 1.8,
      lineHeightLG: 1.5714285714285714,
      lineHeightHeading1: 1.25,
      lineHeightHeading2: 1.3,
      lineHeightHeading3: 1.4,
      lineHeightHeading4: 1.5,
      lineHeightHeading5: 1.5714285714285714,
      borderRadius: 8,
      borderRadiusSM: 6,
      borderRadiusXS: 4,
      borderRadiusLG: 8,
    },
  },
}
const THEME: ThemeConfig = {
  token: {
    colorPrimary: '#7716FF',
    colorText: '#2d2d2d',
    fontWeightStrong: 600,
    ...TOKEN_THEME.token.size,
  },
  components: {
    Dropdown: {
      // borderRadiusLG: 10,
    },
    Button: {
      borderRadius: 6,
      borderRadiusXS: 6,
      borderRadiusSM: 6,
      borderRadiusLG: 8,
      borderRadiusOuter: 12,
      fontWeight: 500,
      colorText: '#696969',
      colorPrimary: '#7716FF',
    },
    Tooltip: {
      paddingSM: 14,
      paddingXS: 20,
    },
    Form: {
      labelColor: '#383838',
    },
    Select: {
      controlHeight: 35,
    },
    Popconfirm: {
      'marginXS': 10,
      marginXXS: 6,
      'algorithm': true,
      'colorTextHeading': 'rgba(77, 77, 77, 0.88)',
      'colorText': 'rgba(129,129,129,0.88)',
    },
    Menu: {
      fontSize: 14,
      fontSizeLG: 16,
      borderRadius: 0,
    },
    Modal: {
      borderRadius: 16,
      borderRadiusLG: 14,
    },
    Input: {
      inputFontSize: 14,
      paddingBlock: 8,
    },
    Steps: {
      iconFontSize: 14,
      fontSize: 14,
      fontSizeLG: 16,
    },
    Layout: {
      headerBg: '#FFFFFF',
      siderBg: '#FFFFFF',
      colorText: 'rgba(0,0,0,0.75)',
    },
    'Radio': {
      'radioSize': 18,
    },
    'Table': {
      'headerBg': 'rgba(250,250,250,0.2)',
    },
    'Statistic': {
      'contentFontSize': 24,
      'titleFontSize': 16,
    },
  },
}

export { THEME, TOKEN_THEME }
