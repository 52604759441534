import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Loading from 'components/shared-components/Loading'
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'
import RouteInterceptor from '../../guard/interceptor.route'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route AuthPage path={`${AUTH_PREFIX_PATH}/login`}
               component={lazy(() => import(`./authentication/login-2`))} />
        <Route AuthPage path={`${AUTH_PREFIX_PATH}/register`}
               component={lazy(() => import(`./authentication/register-2`))} />
        <Route AuthPage path={`${AUTH_PREFIX_PATH}/forgot-password`}
               component={lazy(() => import(`./authentication/forgot-password`))} />
        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews

