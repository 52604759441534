import { FAILURE, REQUEST, SUCCESS, VALIDATE_QS } from '../actions/action.type'
import { EDIT0R_GET_DETAIL_QUIZ, EDITOR_CHANGE_QUIZ_TYPE, EDITOR_GET_QUESTION_QUIZ } from '../constants/Editor'

const _initialState = {
  quiz: {
    loading: true,
    message: null,
    error: false,
    data: null,
  },
  type: null,
  question: {
    loading: true,
    error: null,
    data: null,
  },
  collections: {},
  layout: {
    content: {
      isError: false,
      isLoading: false,
      isReady: true,
      error: null,
    },
  },
}

/**
 * ### Editor
 * @param state
 * @param {{type: string, payload: any}}action
 */
export default function (state = _initialState, { type, payload }) {
  switch (type) {

    case REQUEST(EDIT0R_GET_DETAIL_QUIZ):
      return {
        ...state,
        quiz: {
          ...state.quiz,
          loading: true,
          message: null,
          error: false,
          data: null,
        },
      }
    case SUCCESS(EDIT0R_GET_DETAIL_QUIZ):
      return {
        ...state,
        quiz: {
          ...state.quiz,
          loading: false,
          message: null,
          error: false,
          data: payload,
        },
      }
    case FAILURE(EDIT0R_GET_DETAIL_QUIZ):
      return {
        ...state,
        quiz: {
          ...state.quiz,
          loading: false,
          message: payload?.message,
          error: true,
          data: null,
        },
      }
    case VALIDATE_QS(EDIT0R_GET_DETAIL_QUIZ):
      return {
        ...state,
        quiz: {
          ...state.quiz,
          loading: false,
          message: payload?.message,
          error: true,
          data: null,
        },
      }
    case REQUEST(EDITOR_GET_QUESTION_QUIZ):
      return {
        ...state,
        layout: {
          ...state.layout,
          content: {
            ...state.layout?.content,
            isLoading: true,
            isReady: false,
            isError: false,
            error: null,
          },
        },
        question: {
          ...state.question,
          loading: true,
          message: null,
          error: false,
          data: null,
        },
      }
    case SUCCESS(EDITOR_GET_QUESTION_QUIZ):
      return {
        ...state,
        layout: {
          ...state.layout,
          content: {
            ...state.layout?.content,
            isLoading: false,
            isReady: true,
            isError: false,
            error: null,
          },
        },
        question: {
          ...state.question,
          loading: false,
          message: null,
          error: false,
          data: payload,
        },
      }
    case FAILURE(EDITOR_GET_QUESTION_QUIZ):
      return {
        ...state,
        question: {
          ...state.question,
          loading: false,
          message: payload?.message,
          error: true,
          data: null,
        },
      }
    case VALIDATE_QS(EDITOR_GET_QUESTION_QUIZ):
      return {
        ...state,
        question: {
          ...state.question,
          loading: false,
          message: payload?.message,
          error: true,
          data: null,
        },
      }

    case REQUEST(EDITOR_CHANGE_QUIZ_TYPE):
      return {
        ...state,
        layout: {
          ...state.layout,
          content: {
            ...state.layout?.content,
            isError: false,
            isLoading: true,
            isReady: false,
            error: null,
          },
        },
      }
    case SUCCESS(EDITOR_CHANGE_QUIZ_TYPE):
      return {
        ...state,
        layout: {
          ...state.layout,
          content: {
            ...state.layout?.content,
            isLoading: false,
            isReady: true,
          },
        },
        question: {
          ...state.question,
          data: payload?.data,
        },
      }
    case FAILURE(EDITOR_CHANGE_QUIZ_TYPE):
      return {
        ...state,
        layout: {
          ...state.layout,
          content: {
            ...state.layout?.content,
            isError: true,
            isLoading: false,
            isReady: false,
            error: payload?.message,
          },
        },
      }
    default:
      return state
  }
}