import {
  SUCCESS,
  FAILURE,
  REQUEST,
  NOTFOUND,
} from '../actions/action.type'
import { QUIZ_COLLECTION_QUESTIONS, QUIZ_SHOW, QUIZZES } from '../constants/Quiz.constant'

const initialState = {
  detail: {
    loading: true,
    error: false,
    message: null,
    data: null,
  },
  collections: {
    quizzes: {
      loading: true,
      error: false,
      message: null,
      data: [],
      params: {
        page: 1,
        limit: 10,
        paranoid: true,
        showAll: true,
        terms: [
          {
            key: 'status',
            operator: 'eq',
            value: 'publish',
          },
        ],
      },
      pagination: {
        page: 1,
        limit: 10,
        max_page: 0,
        total_record: 0,
      },
    },
    questions: {
      loading: true,
      error: false,
      message: null,
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        max_page: 0,
        total_record: 0,
      },
    },
  },
}

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST(QUIZZES):
      if (typeof (payload?.type) !== 'undefined') {
        if (payload?.type !== 'load-more') {
          state.collections.quizzes.data = []
        }
      } else {
        state.collections.quizzes.data = []
      }
      return {
        ...state,
        collections: {
          ...state.collections,
          quizzes: {
            ...state.collections.quizzes,
            loading: true,
          },
        },
      }
    case SUCCESS(QUIZZES):
      if (typeof (payload?.type) !== 'undefined') {
        if (payload?.type === 'load-more') {
          state.collections.quizzes.data = [...state.collections.quizzes.data, ...payload?.data]
        } else {
          state.collections.quizzes.data = [...payload?.data]
        }
      } else {
        state.collections.quizzes.data = []
      }
      return {
        ...state,
        collections: {
          ...state.collections,
          quizzes: {
            ...state.collections.quizzes,
            loading: false,
            pagination: payload?.pagination ?? [],
          },
        },
      }
    case FAILURE(QUIZZES):
      if (typeof (payload?.type) !== 'undefined') {
        if (payload?.type !== 'load-more') {
          state.collections.quizzes.data = []
        }
      } else {
        state.collections.quizzes.data = []
      }
      return {
        ...state,
        collections: {
          ...state.collections,
          quizzes: {
            ...state.collections.quizzes,
            loading: false,
            pagination: payload?.pagination ?? [],
          },
        },
      }

    case REQUEST(QUIZZES + '_LOAD_MORE'):
      return {
        ...state,
        collections: {
          ...state.collections,
          quizzes: {
            ...state.collections.quizzes,
            loading: true,
            data: [...state.collections.quizzes.data],
          },
        },
      }
    case SUCCESS(QUIZZES + '_LOAD_MORE'):
      return {
        ...state,
        collections: {
          ...state.collections,
          quizzes: {
            ...state.collections.quizzes,
            loading: false,
            data: [...state.collections.quizzes?.data, ...payload?.data],
            pagination: payload?.pagination ?? [],
          },
        },
      }
    case FAILURE(QUIZZES + '_LOAD_MORE'):
      return {
        ...state,
        collections: {
          ...state.collections,
          quizzes: {
            ...state.collections.quizzes,
            loading: false,
            pagination: payload?.pagination ?? [],
          },
        },
      }

    case REQUEST(QUIZ_SHOW):
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          message: null,
          error: false,
          data: null,
        },
      }
    case NOTFOUND(QUIZ_SHOW):
    case FAILURE(QUIZ_SHOW):
      return {
        ...state,
        detail: {
          ...state.detail,
          ...payload,
          loading: false,
          message: payload?.message,
          error: payload?.error ?? false,
          data: null,
        },
      }
    case SUCCESS(QUIZ_SHOW):
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          message: null,
          error: false,
          data: payload?.data,
        },
      }

    case REQUEST(QUIZ_COLLECTION_QUESTIONS):
      return {
        ...state,
        collections: {
          ...state.collections,
          questions: {
            ...state.collections.questions,
            loading: true,
            data: [],
          },
        },
      }
    case SUCCESS(QUIZ_COLLECTION_QUESTIONS):
      return {
        ...state,
        collections: {
          ...state.collections,
          questions: {
            ...state.collections.questions,
            loading: false,
            data: payload?.data ?? [],
            pagination: {
              ...state.collections.questions.pagination,
              ...payload?.pagination,
            },
          },
        },
      }
    case FAILURE(QUIZ_COLLECTION_QUESTIONS):
      return {
        ...state,
        collections: {
          ...state.collections,
          questions: {
            ...state.collections.questions,
            loading: false,
            data: [],
          },
        },
      }
    default:
      return state
  }

}