import axios from 'axios'
import { message } from 'antd' // function Logout in redux action
import qs from 'qs'
import { Handler } from '../utils/handler'
import { __APP_STORAGE__ } from '../constants/app.constant'
import { GetHost } from '../utils/get-host'
import { __NETWORK_ERROR_TYPE } from '../constants/network.constant'

let TOKEN = null
if (localStorage.getItem(__APP_STORAGE__.AUTH_TOKEN)) {
  try {
    let NewToken = localStorage.getItem(__APP_STORAGE__.AUTH_TOKEN)
    if (typeof (NewToken) !== 'undefined') {
      TOKEN = NewToken
    }
  } catch (err) {
    TOKEN = ''
  }
}

let headers = {
  'Authorization': Handler.token('get') ?? TOKEN,
}

let baseUrl

const { api } = new GetHost().getUrl()
baseUrl = api

const AxiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    ...headers,
  },
  paramsSerializer: (param) => {
    return qs.stringify(param)
  },
})

AxiosInstance.isCancel = axios.isCancel

AxiosInstance.interceptors.response.use(
  (res) =>
    new Promise((resolve) => {
      if (res.data.error === __NETWORK_ERROR_TYPE.UN_AUTHORIZED) {
      } else {
        resolve(res)
      }
    }),

  (err) => {
    if (!err.response) {

      if (err?.isAxiosError) {

      }

      return new Promise((resolve, reject) => {
        reject({ ...err })
      })
    }
    if (err.response.status === 401) {
      return new Promise((resolve, reject) => {
        reject({ ...err })
      })
    } else {
      return new Promise((resolve, reject) => {
        reject({ ...err?.response?.data })
      })
    }
  },
)

export default AxiosInstance
