import { EVENT, FAILURE, REQUEST, SUCCESS } from '../actions/action.type'
import { TEACHER_LIBRARY, TEACHER_LIBRARY_ANALYTICS, TEACHER_LIBRARY_LAYOUT } from '../constants/Teacher'
import { Handler } from '../../views/app-views/teacher-scope/helpers'

const initialState = {
  analytics: {
    loading: false,
    data: [
      {
        key: 'average-score',
        label: [],
        value: [
          0,
          0,
          0,
          0,
        ],
      },
      {
        key: 'average-duration',
        label: [],
        value: [
          0,
          0,
          0,
          0,
        ],
      },
      {
        key: 'average-correct',
        label: [],
        value: [
          0,
          0,
          0,
          0,
        ],
      },
      {
        key: 'average-incorrect',
        label: [],
        value: [
          0,
          0,
          0,
          0,
        ],
      },
      {
        key: 'average-reattempt',
        label: [],
        value: [
          0,
          0,
          0,
          0,
        ],
      },
    ],
    average_duration: {
      label: [],
      color: Handler.COLOR,
      data: [],
      options: {
        plotOptions: {
          pie: {
            size: 300,
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  fontSize: '18px',
                  fontFamily: 'Roboto',
                  fontWeight: 'bold',
                  color: '#1a3353',
                  formatter: function (w) {
                    let _total = w?.globals?.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                    let _length = w?.globals?.seriesTotals.length

                    return `${(_total / _length).toFixed(2)}/s`
                  },
                },
              },
              size: '65%',
            },
          },
        },
        labels: [],
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: true,
        },
      },
    },
    average: {
      score: {
        label: [],
        color: Handler.COLOR,
        data: [],
        options: {
          plotOptions: {
            pie: {
              size: 300,
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: true,
                    showAlways: true,
                    label: 'Duration test',
                    fontSize: '18px',
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    color: '#1a3353',
                    formatter: function (w) {
                      let _total = w?.globals?.seriesTotals.reduce((a, b) => {
                        return a + b
                      }, 0)
                      let _length = w?.globals?.seriesTotals.length
                      return `${(_total / _length).toFixed(2)}`
                    },
                  },
                },
                size: '65%',
              },
            },
          },
          labels: [],
          dataLabels: {
            enabled: true,
          },
          legend: {
            show: true,
          },
        },
      },
      correct: {
        label: [],
        color: Handler.COLOR,
        data: [],
        options: {
          plotOptions: {
            pie: {
              size: 300,
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: true,
                    showAlways: true,
                    fontSize: '18px',
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    color: '#1a3353',
                    formatter: function (w) {
                      let _total = w?.globals?.seriesTotals.reduce((a, b) => {
                        return a + b
                      }, 0)
                      let _length = w?.globals?.seriesTotals.length
                      return `${(_total / _length).toFixed(2)}`
                    },
                  },
                },
                size: '65%',
              },
            },
          },
          labels: [],
          dataLabels: {
            enabled: true,
          },
          legend: {
            show: true,
          },
        },
      },
      incorrect: {
        label: [],
        color: Handler.COLOR,
        data: [],
        options: {
          plotOptions: {
            pie: {
              size: 300,
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: true,
                    showAlways: true,
                    fontSize: '18px',
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    color: '#1a3353',
                    formatter: function (w) {
                      let _total = w?.globals?.seriesTotals.reduce((a, b) => {
                        return a + b
                      }, 0)
                      let _length = w?.globals?.seriesTotals.length
                      return `${(_total / _length).toFixed(2)}`
                    },
                  },
                },
                size: '65%',
              },
            },
          },
          labels: [],
          dataLabels: {
            enabled: true,
          },
          legend: {
            show: true,
          },
        },
      },
    },
    percentage: {
      correct: {
        label: [],
        color: Handler.COLOR,
        data: [],
        options: {
          plotOptions: {
            pie: {
              size: 300,
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: true,
                    showAlways: true,
                    fontSize: '18px',
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    color: '#1a3353',
                    formatter: function (w) {
                      let _total = w?.globals?.seriesTotals.reduce((a, b) => {
                        return a + b
                      }, 0)
                      let _length = w?.globals?.seriesTotals.length
                      return `${(_total / _length).toFixed(2)}`
                    },
                  },
                },
                size: '65%',
              },
            },
          },
          labels: [],
          dataLabels: {
            enabled: true,
          },
          legend: {
            show: true,
          },
        },
      },
      incorrect: {
        label: [],
        color: Handler.COLOR,
        data: [],
        options: {
          plotOptions: {
            pie: {
              size: 300,
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: true,
                    showAlways: true,
                    fontSize: '18px',
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    color: '#1a3353',
                    formatter: function (w) {
                      let _total = w?.globals?.seriesTotals.reduce((a, b) => {
                        return a + b
                      }, 0)
                      let _length = w?.globals?.seriesTotals.length
                      return `${(_total / _length).toFixed(2)}`
                    },
                  },
                },
                size: '65%',
              },
            },
          },
          labels: [],
          dataLabels: {
            enabled: true,
          },
          legend: {
            show: true,
          },
        },
      },
    },
  },
  library: {
    options: {
      layout: 'card',
    },
    loading: true,
    pagination: {
      page: 1,
      limit: 10,
      max_page: 0,
      total_record: 0,
    },
    data: [],
    collections: {},
  },
}

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case EVENT(TEACHER_LIBRARY_LAYOUT):
      return {
        ...state,
        library: {
          ...state.library,
          options: {
            ...state.library.options,
            layout: payload,
          },
        },
      }
    case REQUEST(TEACHER_LIBRARY):
      return {
        ...state,
        library: {
          ...state.library,
          loading: true,
//          data: [],
        },
      }
    case SUCCESS(TEACHER_LIBRARY):
      return {
        ...state,
        library: {
          ...state.library,
          loading: false,
          data: [...payload?.data],
          pagination: {
            ...state.library.pagination,
            ...payload?.pagination,
          },
        },
      }
    case FAILURE(TEACHER_LIBRARY):
      return {
        ...state,
        library: {
          ...state.library,
          loading: false,
          data: [],
        },
      }

    case REQUEST(TEACHER_LIBRARY_ANALYTICS):
      return {
        ...state,
        analytics: {
          ...state.analytics,
          loading: true,
        },
      }
    case SUCCESS(TEACHER_LIBRARY_ANALYTICS):
      return {
        ...state,
        analytics: {
          ...state.analytics,
          loading: false,
          data: payload?.data ?? [],
        },
      }
    case FAILURE(TEACHER_LIBRARY_ANALYTICS):
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...initialState.analytics,
          loading: false,
          data: [],
        },
      }
    default:
      return state
  }
}