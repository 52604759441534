import ApiService from '../api.service'

export class GameSagaService {

  /**
   * @param {object} payload
   * @param {object} payload.params
   * @param {string} payload.uuid - Table quiz - column uuid
   */
  static getGame (payload) {
    return Promise.resolve(new ApiService({
      url: `/api/v1/quiz/${payload?.uuid}/overview`,
      config: {
        params: {
          ...payload?.params,
        },
      },
    }).get())
  }

  /**
   * @param {object} payload
   * @param {object} payload.uuid
   * @return {Promise<Awaited<Promise<*|(*&{data: null, error: boolean, message: Error})|undefined>>>}
   */
  static sendAnswerOverall (payload) {
    return Promise.resolve(new ApiService({
      url: `/api/v1/quiz/${payload?.uuid}/answer-overall`,
      body: payload,
    }).post())
  }

  /**
   * # Get Questions
   * @param {object} payload
   * @param {object} payload.params
   * @param {number} payload.params.page
   * @param {number} payload.params.limit
   * @param {Array<{key:string,operator: string, value:number|string}>} payload.params.terms
   * @param {string} payload.quizUuid
   * @returns {Promise<unknown>}
   */
  static getQuestions (payload = {}) {
    let _ = {
      params: {
        page: payload?.params?.page ?? 1,
        limit: payload?.params?.limit ?? 1,
        limit: 10,
        terms: [],
      },
    }
    if (typeof (payload?.params) !== 'undefined' && typeof (payload?.params?.terms) !== 'undefined' && Array.isArray(payload?.params?.terms)) {
      Reflect.set(_.params, 'terms', payload?.params?.terms)
    }

    return Promise.resolve(new ApiService({
      url: `/api/v1/quiz/question`,
      config: {
        params: {
          ..._?.params,
          terms: [
            ..._?.params?.terms,
            {
              key: 'quizUuid',
              operator: 'eq',
              value: payload?.quizUuid,
            },
          ],
        },
      },
    }).get())
  }
}