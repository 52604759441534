import { QUIZ_HINTS_SEPARATOR } from '../constants/quiz-type.constant'

/**
 * ### Assert Test ID
 * @example
 * <YourComponent dataTestId={assertTestId('btn-primary','tx')}/>{ReactNode}</YourComponent>
 * @param {string} testId
 * @param {string | 'tx' | 'rx'} prefix
 * @returns {string}
 */
export function assertTestId (testId, prefix = 'tx') {
  return [prefix, testId].join('-')
}

/**
 * ### Assert Test
 * @example
 * <YourComponent {...assertTest('btn', { name: 'btn-primary', prefix: 'rx'}}>
 *   {ReactNode}
 * </YourComponent>
 * @param {string} name
 * @param {object} test
 * @param {string} test.name
 * @param {string | 'tx' | 'rx'} test.prefix
 * @returns {{'data-core-id': (string|string), 'data-testid': (string|string)}}
 */
export function assertTest (name, test) {
  const dataTestId = assertTestId(test.name ?? 'name', test?.prefix ?? 'tx') ?? ''
  const coreId = assertTestId(name, 'rx') ?? ''
  return {
    'data-testid': dataTestId,
    'data-core-id': coreId,
  }
}

/**
 * @param {string | Array<string>} classes
 * @param {string | 'core'} prefix
 * @param prefix
 * @returns {*|string}
 */
export function assertClassName (classes, prefix = 'core') {
  if (Array.isArray(classes) && classes.length > 0) return classes.map(child => [prefix, child].join(prefix !== null ? '-' : '')).join(' ')
  return [prefix, classes].join(prefix !== null ? '-' : '')
}

export function GetYoutubeID (url) {
  const _url = new URLSearchParams(url)
  if (!_url.get('autoplay')) {
    _url.append('autoplay', '1')
  }
  let _newURL = decodeURIComponent(_url.toString())
  console.log({ _newURL })
  let videoId = null

  // Regex untuk menangani berbagai jenis URL YouTube
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  const match = _newURL.match(regex)

  if (match && match[1]) {
    videoId = match[1]
  } else {
    // Jika input adalah embed code, ekstrak ID video
    const embedRegex = /youtube\.com\/embed\/([a-zA-Z0-9_-]{11})/
    const embedMatch = url.match(embedRegex)
    if (embedMatch && embedMatch[1]) {
      videoId = embedMatch[1]
    }
  }

  return videoId
}

export function GetThumbnailYoutube (youtubeId, quality = 'hqdefault.jpg') {
  return [
    'https://img.youtube.com/vi',
    youtubeId,
    quality,
  ].join('/')
}

export function ConvertYoutubeIDToUrlZEmbed (youtubeID) {
  return `https://www.youtube.com/embed/${youtubeID}`
}

export const removeIframeByUrl = (htmlString, url, replaceTo = '') => {
  if (typeof (htmlString) === 'undefined') return replaceTo
  const regex = new RegExp(`<iframe[^>]*[^>]*><\\/iframe>`, 'gi')

  const iframeExists = regex.test(htmlString)
  if (iframeExists) return htmlString.replace(regex, replaceTo ?? '')
  return htmlString + replaceTo
}

export const ConvertYoutubeIdToIframe = (videoId, width = 560, height = 315) => {
  if (!videoId) {
    return null
  }

  return `
    <iframe
      width="${width}"
      height="${height}"
      src="https://www.youtube.com/embed/${videoId}"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  `
}

export function ConvertStrToArrayOfObject (input) {
  return input.split('').map((char, index) => ({
    index: index,
    type: char === ' ' ? QUIZ_HINTS_SEPARATOR : 'text',
    value: char === ' ' ? null : char,
  }))
}