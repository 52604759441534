import React from 'react'
import { Button } from 'antd'
import { Icons } from '../../../../components/atoms/general/icons/Icons'
import { useDispatch, useSelector } from 'react-redux'
import { GAME_STATUS } from '../../../../constants/game.constant'
import { GameAudioStatus } from '../../../../redux/actions/Game'

/**
 * @param {object} props
 * @param {CSSStyleSheet} props.style
 * @returns {Element}
 */
function AudioPlayerBtn (props) {

  const dispatch = useDispatch()
  const { quiz, game } = useSelector(({ Game }) => Game)
  const { status } = useSelector(({ Game }) => Game.setting.audio.options)
  const toggle = () => {
    if (game?.status === GAME_STATUS.COMPLETED) {
      dispatch(GameAudioStatus({
        options: {
          status: status === 'play' ? 'pause' : 'play',
        },
        url: quiz?.data?.theme?.musicCredit,
      }))
    } else {
      dispatch(GameAudioStatus({
        options: {
          status: status === 'play' ? 'pause' : 'play',
        },
        url: quiz?.data?.theme?.musicBackground,
      }))
    }
  }

  return (
    <React.Fragment>
      <Button
        style={{
          ...props?.style,
          minWidth: '40px',
        }}
        onClick={toggle}
        type={'primary'}
        shape={'circle'}
        icon={<Icons type={status === 'play' ? 'SoundOutlined' : 'MutedOutlined'} style={{ fontSize: 16 }} />}
      />
    </React.Fragment>
  )
}

export { AudioPlayerBtn }