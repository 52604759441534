import ApiService from '../api.service'

export class OptionsSagaService {

  /**
   * @param {object} payload
   * @param {object} payload.params
   * @param {string} payload.uuid - Table quiz - column uuid
   */
  static getOptions (payload) {
    return Promise.resolve(new ApiService({
      url: `/api/v1/options`,
      config: {
        params: {
          ...payload?.params,
        },
      },
    }).get())
  }
}