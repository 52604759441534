import React from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import Loading from '../../../components/shared-components/Loading'

function PlayLayout ({ match }) {

  return (
    <React.Suspense fallback={<Loading cover={'page'} />}>
      <Switch>
        <Route exact path={`${match.url}/join`} component={React.lazy(() => import('../screens/join-game'))} />
        <Route path={`${match.url}/game`} component={React.lazy(() => import('../screens/game'))} />
        <Route>
          <Redirect to={`${match.url}/join`} />
        </Route>
      </Switch>
    </React.Suspense>
  )
}

export default withRouter(PlayLayout)