import { takeEvery, call, delay, all, put, fork } from 'redux-saga/effects'
import { FAILURE, REQUEST, SUCCESS } from '../actions/action.type'
import {
  ANALYTICS_DASHBOARD_GENERAL,
  GET_ANALYTICS_PARTICIPANTS,
  GET_ANALYTICS_PARTICIPANTS_USERS,
} from '../constants/Analytics'
import { AnalyticsSagaService } from '../../services/saga/analytics.saga.service'

export function * getParticipants () {
  yield takeEvery(REQUEST(GET_ANALYTICS_PARTICIPANTS), function * ({ payload }) {
    try {
      const response = yield call(AnalyticsSagaService.getAnalyticParticipants, payload)

      if (!response?.error) {
        yield put({
          type: SUCCESS(GET_ANALYTICS_PARTICIPANTS),
          payload: {
            ...response,
          },
        })
      } else {
        yield put({
          type: FAILURE(GET_ANALYTICS_PARTICIPANTS),
          payload: {},
        })
      }
    } catch (err) {
      yield put({
        type: FAILURE(GET_ANALYTICS_PARTICIPANTS),
        payload: {},
      })
    }
  })
}

export function * getParticipantsUsers () {
  yield takeEvery(REQUEST(GET_ANALYTICS_PARTICIPANTS_USERS), function * ({ payload }) {
    try {
      const response = yield call(AnalyticsSagaService.getParticipants, payload)

      if (!response?.error) {
        yield put({
          type: SUCCESS(GET_ANALYTICS_PARTICIPANTS_USERS),
          payload: {
            ...response,
          },
        })
      } else {
        yield put({
          type: FAILURE(GET_ANALYTICS_PARTICIPANTS_USERS),
          payload: {},
        })
      }
    } catch (err) {
      yield put({
        type: FAILURE(GET_ANALYTICS_PARTICIPANTS_USERS),
        payload: {},
      })
    }
  })
}

export function * getDashboardGeneral () {
  yield takeEvery(REQUEST(ANALYTICS_DASHBOARD_GENERAL), function * ({}) {
    try {
      const response = yield call(AnalyticsSagaService.getDashboardGeneral, {})

      if (!response?.error) {
        yield put({
          type: SUCCESS(ANALYTICS_DASHBOARD_GENERAL),
          payload: response?.data,
        })
      } else {
        yield put({
          type: FAILURE(ANALYTICS_DASHBOARD_GENERAL),
          payload: {},
        })
      }
    } catch (err) {
      yield put({
        type: FAILURE(ANALYTICS_DASHBOARD_GENERAL),
        payload: {},
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getParticipants),
    fork(getParticipantsUsers),
    fork(getDashboardGeneral),
  ])
}