import ApiService from '../api.service'

export class AnalyticsSagaService {
  static API = ApiService

  /**
   *
   * @param {object} payload
   * @param {object} payload.params
   * @param {boolean | true | false} payload.params.quiz
   * @param {boolean | true | false} payload.params.showAll
   * @param api
   * @return {Promise<unknown>}
   */
  static getParticipants (payload, api = AnalyticsSagaService.API) {
    return new Promise((resolve) => {
      resolve(new api({
        url: `/api/v1/quiz/analytics/participants`,
        config: {
          params: {
            ...payload?.params,
            state: process.env.REACT_APP_ENV === 'PRD' ? 'prd' : 'dev',
          },
        },
      }).get())
    })
  }

  static getAnalyticParticipants (payload, api = AnalyticsSagaService.API) {
    return new Promise((resolve) => {
      resolve(new api({
        url: `/api/v1/quiz/analytics/participants/list`,
        config: {
          params: {
            ...payload?.params,
            state: process.env.REACT_APP_ENV === 'PRD' ? 'prd' : 'dev',
          },
        },
      }).get())
    })
  }

  static getDashboardGeneral (payload, api = AnalyticsSagaService.API) {
    return new Promise((resolve) => {
      resolve(new api({
        url: `/api/v1/analytic/dashboard`,
        config: {
          params: {
            ...payload?.params,
            state: process.env.REACT_APP_ENV === 'PRD' ? 'prd' : 'dev',
          },
        },
      }).get())
    })
  }

  static getAverageUser (payload, api = AnalyticsSagaService.API) {
    return new Promise((resolve) => {
      resolve(new api({
        url: `/api/v1/analytic/average/user`,
        config: {
          params: {
            ...payload?.params,
            state: process.env.REACT_APP_ENV === 'PRD' ? 'prd' : 'dev',
          },
        },
      }).get())
    })
  }

}