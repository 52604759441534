import { createContext } from 'react'
import { Form } from 'antd'

export const FormContext = createContext({})

export const FormProvider = ({ children }) => {
  const [form] = Form.useForm()
  return (
    <FormContext.Provider
      value={{
        form,
      }}
    >
      <Form
        form={form}
        name={'quiz-form'}
        initialValues={{}}
        noStyle
        className={'!cr-flex !cr-flex-col !cr-flex-1'}
      >
        {children}
      </Form>
    </FormContext.Provider>

  )
}