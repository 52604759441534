import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetAllSiteConfiguration } from '../redux/actions/Site'
import LottieLoading from '../components/animated-components/lottie-loading'
import Animation from '../assets/lotties/lottie-flight.json'

function SiteProvider ({ children }) {
  const dispatch = useDispatch()
  const { collections } = useSelector(state => state.Sites)
  useEffect(() => {
    dispatch(GetAllSiteConfiguration())
  }, [])
  if (collections?.loading) {
    return (
      <div className={'cr-flex cr-justify-center cr-items-center cr-h-screen cr-w-full'}>
        <LottieLoading
          width={200}
          height={200}
          animationData={Animation}
          cover={'page'} />
      </div>
    )
  }
  return children
}

export default SiteProvider