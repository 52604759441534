import { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { _loadUser } from '../redux/actions'

function LoadUser (props) {
  let { _loadUser } = props
  const { collections } = useSelector(state => state.Sites)
  if (collections.loading) return null

  useEffect(() => {
    if (collections.loading === false) {
      _loadUser()
    }
  }, [collections.loading])

  return props?.children
}

export default connect(({ auth }) => {
  let {} = auth
  return { auth }
}, {
  _loadUser,
})(LoadUser)