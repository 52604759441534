import {
  AUTHENTICATED,
  SIGNOUT,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SHOW_LOADING, AUTH_LOAD_USER, USER_CHANGE_AVATAR, USER_CHANGE_PROFILE,
} from '../constants/Auth'
import { EVENT, REQUEST } from './action.type'

export function _loadUser () {
  return {
    type: REQUEST(AUTH_LOAD_USER),
  }
}

export const authenticated = (token) => {
  return {
    type: AUTHENTICATED,
    token,
  }
}

export const signOut = () => {
  return {
    type: SIGNOUT,
  }
}

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message,
  }
}

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  }
}

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  }
}

/**
 * @param {object} payload
 * @param {string} payload.avatar
 * @return {{payload, type: string}}
 */
export function ChangeAvatar (payload) {
  return {
    type: REQUEST(USER_CHANGE_AVATAR),
    payload: payload,
  }
}

export function ChangeProfileInformation (payload) {
  return {
    type: REQUEST(USER_CHANGE_PROFILE),
    payload: payload,
  }
}
