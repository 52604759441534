import { createContext, useEffect, useState } from 'react'
import { Form } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

const EditorContext = createContext({})
export const EditorProvider = ({ children }) => {
  const history = useHistory()
  const question = useSelector((state) => state.editor.question)
  const editor = useSelector((state) => state.editor)
  const _search = new URLSearchParams(history.location.search)
  const [form] = Form.useForm()
  const [state, setState] = useState(() => {
    let _ = {
      form: {
        layout: 'vertical',
      },
      initialValues: {},
      data: {},
    }

    if (_search.get('quizUuid')) {
      Reflect.set(_.data, 'quizUuid', _search.get('quizUuid'))
    }
    return _
  })
  const param = useParams()

  useEffect(() => {
    if (!question?.loading && question?.data) {
      setState({
        ...state,
        initialValues: {
          ...state?.initialValues,
          ...question?.data,
          quizTypeUuid: question?.data?.quizTypeUuid,
        },
      })
    }
  }, [question])

  useEffect(() => {
    form.resetFields()
  })
  return <EditorContext.Provider value={{
    form,
    param,
    state,
    setState,
  }}>
    <Form
      {...state?.form}
      layout={'vertical'}
      initialValues={{
        ...state?.initialValues,
        content: state?.initialValues?.content ?? '',
      }}
      form={form}
    >
      {children}
    </Form>
  </EditorContext.Provider>
}

export default EditorContext