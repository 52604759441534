import React, { useState } from 'react'
import { APP_NAME } from 'configs/AppConfig'
import { Icons } from '../atoms/general/icons/Icons'
import { FloatButton } from 'antd'
import { useSelector } from 'react-redux'
import ReactJson from 'react-json-view'

export default function Footer () {
  const state = useSelector(state => state)
  const [active, setActive] = useState(false)
  return (
    <footer className="footer">
      <div
        className={`${!active ? 'cr-hidden' : ''} cr-fixed cr-right-0 cr-top-0 cr-h-screen cr-w-[320px] cr-z-[1010] cr-bg-white`}>
        <ReactJson
          src={state} collapsed={1} enableClipboard style={{
          fontSize: 8,
        }} />
      </div>
      {/* <FloatButton */}
      {/*   onClick={() => setActive(!active)} */}

      {/*   icon={<Icons type={'QuestionCircleOutlined'} />} */}
      {/*   type="default" */}
      {/*   style={{ */}
      {/*     right: 40, */}
      {/*     zIndex: 1011, */}
      {/*   }} /> */}
      <span>Copyright  &copy;  {`${new Date().getFullYear()}`} <span
        className="font-weight-semibold">{`${APP_NAME}`}</span> All rights reserved.</span>
    </footer>
  )
}

