import { takeEvery, call, all, put, fork, delay } from 'redux-saga/effects'
import { EVENT, FAILURE, REQUEST, SUCCESS } from '../actions/action.type'

import {
  GAME_CHECK_POINT,
  GAME_CLOSE_BUMPER,
  GAME_FORCE_SUBMIT, GAME_NEXT_PROGRESS, GAME_SEND_RESULT,
  GAME_START, GAME_SUBMIT_QUESTION,
  GET_GAME,
  GET_QUESTION_GAME,
} from '../constants/Game'
import { GameSagaService } from '../../services/saga/game.saga.service'
import { GAME_STATUS } from '../../constants/game.constant'

export function * _getGame () {
  yield takeEvery(REQUEST(GET_GAME), function * ({ payload }) {
    try {
      const response = yield call(GameSagaService.getGame, payload)
      if (!response?.error) {
        yield all([
          put({
            type: SUCCESS(GET_GAME),
            payload: {
              ...response?.data,
            },
          }),
        ])
      } else {
        yield put({
          type: FAILURE(GET_GAME),
          payload: response?.data,
        })
      }
    } catch (err) {
      yield put({
        type: FAILURE(GET_GAME),
        payload: {},
      })
    }
  })
}

export function * getQuestionGame () {
  yield takeEvery(REQUEST(GET_QUESTION_GAME), function * ({ payload }) {
    try {
      const response = yield call(GameSagaService.getQuestions, payload)
      if (!response?.error) {
        yield all([
          put({
            type: SUCCESS(GET_QUESTION_GAME),
            payload: {
              data: [...response?.data],
              pagination: response?.pagination ?? {},
            },
          }),
        ])
      } else {
        yield all([
          put({
            type: FAILURE(GET_QUESTION_GAME),
            payload: response,
          }),
        ])
      }
    } catch (err) {
      yield all([
        put({
          type: FAILURE(GET_QUESTION_GAME),
          payload: err,
        }),
      ])
    }
  })
}

export function * _forceSubmit () {
  yield takeEvery(EVENT(GAME_FORCE_SUBMIT), function * ({ payload }) {
    try {
      yield put({
        type: EVENT(GAME_CHECK_POINT),
        payload,
      })
      yield delay(2000)
      yield put({
        type: EVENT(GAME_NEXT_PROGRESS),
        payload,
      })

    } catch (e) {

    }
  })
}

export function * _SubmitQuestion () {
  yield takeEvery(EVENT(GAME_SUBMIT_QUESTION), function * ({ payload }) {
    try {
      yield put({
        type: EVENT(GAME_CHECK_POINT),
        payload,
      })
      yield delay(2000)
      yield put({
        type: EVENT(GAME_NEXT_PROGRESS),
        payload,
      })

    } catch (e) {

    }
  })
}

// hit api setelah selesai isi question
export function * _gameComplete () {
  yield takeEvery(REQUEST(GAME_SEND_RESULT), function * ({ payload }) {
    try {
      Reflect.set(payload, 'uuid', payload?.quizUuid)
      const response = yield call(GameSagaService.sendAnswerOverall, payload)
      if (!response?.error) {
        yield put({
          type: SUCCESS(GAME_SEND_RESULT),
          payload: response?.data,
        })
      } else {
        yield put({
          type: FAILURE(GAME_SEND_RESULT),
          payload: response?.data,
        })
      }
    } catch (err) {
      yield put({
        type: FAILURE(GAME_SEND_RESULT),
        payload: err,
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getQuestionGame),
    fork(_getGame),
    fork(_forceSubmit),
    fork(_SubmitQuestion),
    fork(_gameComplete),
//    fork(_gameStart),
  ])
}