import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Slider, Modal, Typography } from 'antd'
import { GameChangeVolume, GameSettingOpen } from '../../../redux/actions'

function SettingGame ({ setting }) {
  const dispatch = useDispatch()
  return (
    <React.Fragment>
      <Modal
        open={setting?.open ?? false}
        title={<Typography.Title level={3}>Setting</Typography.Title>}
        destroyOnClose
        closable={false}
        maskClosable={false}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
        onOk={() => dispatch(GameSettingOpen(false))}
      >
        <React.Fragment>
          <Typography.Paragraph>Volume</Typography.Paragraph>
          <Slider
            defaultValue={(setting?.audio?.options?.volume * 100)}
            onChange={function (val) {
              console.log({ val })
              dispatch(GameChangeVolume(val / 100))
            }}
          />
        </React.Fragment>
      </Modal>
    </React.Fragment>
  )
}

export default connect(({ Game }) => {
  let { setting } = Game
  return { setting }
}, {})(SettingGame)