import storage from 'redux-persist/lib/storage'

export const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [
    'auth',
  ],
}

export const gamePersistConfig = {
  key: 'game',
  storage,
  whitelist: [
    'game',
    'answer',
    'setting',
  ],

}
export const QuizPersistConfig = {
  key: 'quizzes',
  storage,
  blacklist: [
    'collections',
  ],

}
export const teacherPersistConfig = {
  key: 'teacher',
  storage,
  blacklist: [
    'analytics',
  ],

}
export const analyticsPersistConfig = {
  key: 'analytics',
  storage,
  blacklist: [
    'participants',
    'miscellaneous',
  ],

}