import { takeEvery, fork, call, put, all } from 'redux-saga/effects'
import { FAILURE, NOTFOUND, REQUEST, SUCCESS } from '../actions/action.type'
import { QUIZ_COLLECTION_QUESTIONS, QUIZ_SHOW, QUIZZES } from '../constants/Quiz.constant'
import { QuizSagaService } from '../../services/saga/quiz.saga.service'
import { __NETWORK_ERROR_TYPE } from '../../constants/network.constant'

export function * _getQuiz () {
  yield takeEvery(REQUEST(QUIZ_SHOW), function * ({ payload }) {
    try {
      const response = yield call(QuizSagaService.getQuizOverview, { quizUuid: payload.uuid, params: {} })

      if (!response?.error) {
        yield put({
          type: SUCCESS(QUIZ_SHOW),
          payload: {
            data: response?.data,
          },
        })
      } else {
        yield put({
          type: NOTFOUND(QUIZ_SHOW),
          payload: {
            message: {
              code: __NETWORK_ERROR_TYPE.SAGA_NOTFOUND,
              description: 'URL could not be found, please use the correct URL',
            },
            error: true,
          },
        })
      }
    } catch (err) {
      yield all([
        put({
          type: FAILURE(QUIZ_SHOW),
          payload: {
            message: err,
            error: true,
          },
        }),
      ])
    }
  })
}

export function * _getQuizzes () {
  yield takeEvery(REQUEST(QUIZZES), function * ({ payload }) {
    try {
      const response = yield call(QuizSagaService.getQuizzes, { ...payload })

      if (!response?.error) {
        yield put({
          type: SUCCESS(QUIZZES),
          payload: {
            ...response,
            type: payload?.type ?? 'default',
          },
        })
      } else {
        yield put({
          type: NOTFOUND(QUIZZES),
          payload: {
            type: payload?.type ?? 'default',
            message: {
              code: __NETWORK_ERROR_TYPE.SAGA_NOTFOUND,
              description: 'URL could not be found, please use the correct URL',
            },
            error: true,
          },
        })
      }
    } catch (err) {
      yield all([
        put({
          type: FAILURE(QUIZZES),
          payload: {
            type: payload?.type ?? 'default',
            message: err,
            error: true,
          },
        }),
      ])
    }
  })
}

export function * _getQuizzesLoadMore () {
  yield takeEvery(REQUEST(QUIZZES + '_LOAD_MORE'), function * ({ payload }) {
    try {
      const response = yield call(QuizSagaService.getQuizzes, { ...payload })

      if (!response?.error) {
        console.log({ response })
        yield put({
          type: SUCCESS(QUIZZES + '_LOAD_MORE'),
          payload: {
            ...response,
          },
        })
      } else {
        yield put({
          type: FAILURE(QUIZZES + '_LOAD_MORE'),
          payload: {
            message: {
              code: __NETWORK_ERROR_TYPE.SAGA_NOTFOUND,
              description: 'URL could not be found, please use the correct URL',
            },
            error: true,
          },
        })
      }
    } catch (err) {
      console.log(err, 'SAGA')
      yield all([
        put({
          type: FAILURE(QUIZZES + '_LOAD_MORE'),
          payload: {
            message: err,
            error: true,
          },
        }),
      ])
    }
  })
}

export function * _getCollectionsQuestions () {
  yield takeEvery(REQUEST(QUIZ_COLLECTION_QUESTIONS), function * ({ payload }) {
    try {

      const response = yield call(QuizSagaService.getCollectionQuestions, {
        quizUuid: payload?.params?.quizUuid,
        ...payload?.params,
      })
      if (!response?.error) {
        yield all([
          put({
            type: SUCCESS(QUIZ_COLLECTION_QUESTIONS),
            payload: {
              data: response?.data,
              pagination: response?.pagination,
            },
          }),
        ])
      } else {
        yield all([
          put({
            type: FAILURE(QUIZ_COLLECTION_QUESTIONS),
            payload: {
              error: true,
              message: {
                code: __NETWORK_ERROR_TYPE.SAGA_ERROR,
                message: response?.message,
              },
            },
          }),
        ])
      }
    } catch (err) {
      yield all([
        put({
          type: FAILURE(QUIZ_COLLECTION_QUESTIONS),
          payload: {
            error: true,
            message: {
              code: __NETWORK_ERROR_TYPE.SAGA_ERROR,
              message: err?.message,
            },
          },
        }),
      ])
    }

  })
}

export default function * rootSaga () {
  yield all([
    fork(_getQuizzesLoadMore),
    fork(_getQuizzes),
    fork(_getQuiz),
    fork(_getCollectionsQuestions),
  ])
}