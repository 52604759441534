import React, { Suspense } from 'react'
import { EditorProvider } from '../../../context/EditorContext'
import { Route, Switch, withRouter } from 'react-router-dom'
import Loading from '../../../components/shared-components/Loading'

function AppEditor ({ match }) {

  return (
    <EditorProvider>
      <Suspense fallback={<Loading cover={'page'} />}>
        <Switch>
          <Route exact path={`${match.url}/:uuid/amend`} component={React.lazy(() => import('../screens/amend'))} />
        </Switch>
      </Suspense>
    </EditorProvider>

  )
}

export default withRouter(AppEditor)