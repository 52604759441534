import { all, takeEvery, call, fork, put } from 'redux-saga/effects'
import { FAILURE, REQUEST, SUCCESS } from '../actions/action.type'
import { AUTH_LOAD_USER, SIGNOUT, SIGNOUT_SUCCESS, USER_CHANGE_AVATAR, USER_CHANGE_PROFILE } from '../constants/Auth'
import { AuthService } from '../../services/auth/auth.service'
import { Handler } from '../../utils/handler'
import { UserSagaService } from '../../services/saga/user.saga.service'
import { message } from 'antd'

export function * loadUser () {
  yield takeEvery(REQUEST(AUTH_LOAD_USER), function * ({}) {
    try {
      const response = yield call(() => {
        return new Promise((resolve) => {
          resolve(new AuthService({}).loadUser())
        })
      })
      let _data = {}
      let _permissions = []
      let _role = null

      if (!response?.error) {
        _permissions = response?.data?.permissions
        _role = response?.data?.role

        Object.entries(response?.data).map(([key, value]) => {
          if (key !== 'permissions' && key !== 'role') {
            Reflect.set(_data, key, value)
          }
        })

        yield all([
          put({
            type: SUCCESS(AUTH_LOAD_USER),
            payload: {
              data: _data,
              permissions: _permissions,
              role: _role,
            },
          }),
        ])
      } else {
        yield all([
          put({
            type: FAILURE(AUTH_LOAD_USER),
            payload: null,
          }),
        ])
      }
    } catch (err) {
      yield all([
        put({
          type: FAILURE(AUTH_LOAD_USER),
          payload: null,
        }),
      ])

    }
  })
}

export function * _signOut () {
  yield takeEvery(SIGNOUT, function * () {
    if (Handler.token('get')) {
      Handler.token('remove')
    }
    yield all([
      put({
        type: SIGNOUT_SUCCESS,
      }),
    ])
  })
}

export function * _changeAvatar () {
  yield takeEvery(REQUEST(USER_CHANGE_AVATAR), function * ({ payload }) {
    try {
      message.loading({
        key: 'patching-avatar',
        content: 'Please Wait',
        duration: 100,
      })
      const response = yield call(UserSagaService.patching, payload)
      if (response?.error) {
        message.error({
          key: 'patching-avatar',
          content: response?.message,
          duration: 5,
        })
        yield all([
          put({
            type: FAILURE(USER_CHANGE_AVATAR),
            payload,
          }),
        ])
      } else {
        message.success({
          key: 'patching-avatar',
          content: response?.message,
          duration: 5,
        })
        yield all([
          put({
            type: SUCCESS(USER_CHANGE_AVATAR),
            payload,
          }),
        ])
      }
    } catch (err) {
      message.error({
        key: 'patching-avatar',
        content: err?.message,
        duration: 5,
      })
      yield all([
        put({
          type: FAILURE(USER_CHANGE_AVATAR),
          payload,
        }),
      ])
    }
  })
}

export function * _changeProfile () {
  yield takeEvery(REQUEST(USER_CHANGE_PROFILE), function * ({ payload }) {
    try {
      message.loading({
        key: 'patching-profile',
        content: 'Please Wait',
        duration: 100,
      })
      const response = yield call(UserSagaService.patching, payload)
      if (response?.error) {
        message.error({
          key: 'patching-profile',
          content: response?.message,
          duration: 5,
        })
        yield all([
          put({
            type: FAILURE(USER_CHANGE_PROFILE),
            payload,
          }),
        ])
      } else {
        message.success({
          key: 'patching-profile',
          content: response?.message,
          duration: 5,
        })
        yield all([
          put({
            type: SUCCESS(USER_CHANGE_PROFILE),
            payload,
          }),
        ])
      }
    } catch (err) {
      message.error({
        key: 'patching-profile',
        content: err?.message,
        duration: 5,
      })
      yield all([
        put({
          type: FAILURE(USER_CHANGE_PROFILE),
          payload,
        }),
      ])
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(_changeAvatar),
    fork(_changeProfile),
    fork(loadUser),
    fork(_signOut),
  ])
}
