import React, { useState } from 'react'
import { Button, Flex, Typography } from 'antd'
import { LoginForm } from '../../../auth-views/components/LoginForm'
import { RegisterForm } from '../../../auth-views/components/RegisterForm'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Loading from '../../../../components/shared-components/Loading'

function AuthGuard ({ isAuthenticated, mode, ...props }) {
  const { loading } = useSelector(({ auth }) => auth)
  const [state, setState] = useState(() => {
    let _modes = ['login', 'register']
    let _ = {
      mode: 'login', // 'login' | 'register'
    }
    if (mode) {
      if (!_modes.some((a) => a !== mode)) throw new Error(`${mode} is not a mode`)
      Reflect.set(_, 'mode', mode)
    }
    return _
  })

  return loading ? <Loading cover={'page'} /> : isAuthenticated ? props.children : (
    <React.Fragment>
      <div className={'h-full cr-flex-1 cr-flex cr-items-center cr-justify-center'}>
        <div
          className={' cr-w-[430px] cr-bg-white cr-rounded-xl p-4 cr-border cr-border-red-500'}
        >
          {
            state.mode === 'login' ? (
              <div className={'cr-flex cr-flex-col'}>
                <div>
                  <Typography.Title level={1}>Login</Typography.Title>
                  <Typography.Paragraph className={'cr-flex cr-items-center cr-gap-2'}>
                    You don't have an account yet ?
                    <Button
                      type="link"
                      ghost
                      onClick={() => setState((prevState) => ({ ...prevState, mode: 'register' }))}
                    >
                      Register
                    </Button>
                  </Typography.Paragraph>
                </div>
                <LoginForm />
              </div>
            ) : (
              <div className={'cr-flex cr-flex-col'}>
                <div>
                  <Typography.Title level={1}>Register</Typography.Title>
                  <Typography.Paragraph className={'cr-flex cr-items-center cr-gap-2 !cr-m-0'}>
                    Do you have account ?
                    <Button
                      type="link"
                      ghost
                      onClick={() => setState((prevState) => ({ ...prevState, mode: 'login' }))}
                    >
                      Login
                    </Button>
                  </Typography.Paragraph>
                </div>
                <RegisterForm
                  addonBefore={<div>

                  </div>}
                  addonAfter={null}
                  onSubmit={function (response) {

                  }}
                  mode={'game'}
                />
              </div>
            )
          }

        </div>
      </div>
    </React.Fragment>
  )
}

AuthGuard.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  mode: PropTypes.oneOf(['login', 'register']),
}

AuthGuard.defaultProps = {
  isAuthenticated: false,
  mode: 'login',
}
export { AuthGuard }