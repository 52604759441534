import { takeEvery, call, delay, all, put, fork } from 'redux-saga/effects'
import { EVENT, FAILURE, REQUEST, SUCCESS, VALIDATE_QS } from '../actions/action.type'
import {
  EDIT0R_GET_DETAIL_QUIZ,
  EDITOR_CHANGE_QUIZ_TYPE,
  EDITOR_GET_QUESTION_QUIZ, EDITOR_REFRESH,
} from '../constants/Editor'
import { QuizSagaService } from '../../services/saga/quiz.saga.service'
import { __NETWORK_ERROR_TYPE } from '../../constants/network.constant'

export function * getQuiz () {
  yield takeEvery(REQUEST(EDIT0R_GET_DETAIL_QUIZ), function * ({ payload }) {
    try {
      if (payload?.quizUuid === null) {
        yield all([
          put({
            type: VALIDATE_QS(EDIT0R_GET_DETAIL_QUIZ),
            payload: {
              error: true,
              message: {
                code: __NETWORK_ERROR_TYPE.SAGA_VALIDATE_QUERY,
                description: 'URL could not be found, please use the correct URL',
              },
            },
          }),
        ])
      } else {
        const response = yield call(QuizSagaService.getQuiz, payload)

        if (payload?.delay) {
          yield delay(payload?.delay ?? 2000)
        }

        if (!response?.error) {

          yield put({
            type: SUCCESS(EDIT0R_GET_DETAIL_QUIZ),
            payload: response?.data,
          })
        } else {
          yield put({
            type: FAILURE(EDIT0R_GET_DETAIL_QUIZ),
            payload: {
              error: true,
              message: {
                code: __NETWORK_ERROR_TYPE.SAGA_NOTFOUND,
                description: 'URL could not be found, please use the correct URL',
              },
            },
          })
        }
      }

    } catch (err) {
      yield put({
        type: FAILURE(EDIT0R_GET_DETAIL_QUIZ),
        payload: {
          error: true,
          message: {
            code: __NETWORK_ERROR_TYPE.SAGA_ERROR,
            description: 'URL could not be found, please use the correct URL',
          },
        },
      })
    }
  })
}

export function * getQuestion () {
  yield takeEvery(REQUEST(EDITOR_GET_QUESTION_QUIZ), function * ({ payload }) {
    try {
      if (payload?.uuid === null) {
        yield all([
          put({
            type: VALIDATE_QS(EDITOR_GET_QUESTION_QUIZ),
            payload: {
              error: true,
              message: {
                code: __NETWORK_ERROR_TYPE.SAGA_VALIDATE_QUERY,
                description: 'URL could not be found, please use the correct URL',
              },
            },
          }),
        ])
      } else {
        const response = yield call(QuizSagaService.getQuestion, payload)
        if (payload?.delay) {
          yield delay(payload?.delay ?? 2000)
        }
        if (!response?.error) {

          yield put({
            type: SUCCESS(EDITOR_GET_QUESTION_QUIZ),
            payload: response?.data,
          })
        } else {
          yield put({
            type: FAILURE(EDITOR_GET_QUESTION_QUIZ),
            payload: {
              error: true,
              message: {
                code: __NETWORK_ERROR_TYPE.SAGA_NOTFOUND,
                description: 'URL could not be found, please use the correct URL',
              },
            },
          })
        }
      }

    } catch (err) {
      yield put({
        type: FAILURE(EDITOR_GET_QUESTION_QUIZ),
        payload: {
          error: true,
          message: {
            code: __NETWORK_ERROR_TYPE.SAGA_ERROR,
            description: 'URL could not be found, please use the correct URL',
          },
        },
      })
    }
  })
}

export function * QuestionChangeType () {
  yield takeEvery(REQUEST(EDITOR_CHANGE_QUIZ_TYPE), function * ({ payload }) {
    try {
      const response = yield call(QuizSagaService.QuestionChangeType, {
        questionUuid: payload?.questionUuid,
        quizTypeUuid: payload?.quizTypeUuid,
      })
      if (response?.error) {
        yield all([
          put({
            type: FAILURE(EDITOR_CHANGE_QUIZ_TYPE),
            payload: {
              message: {
                code: __NETWORK_ERROR_TYPE.SAGA_NOT_ACCEPTABLE,
                message: response?.message ?? 'Failed to change quiz type',
              },
            },
          }),
        ])
      } else {
        yield all([
          put({
            type: SUCCESS(EDITOR_CHANGE_QUIZ_TYPE),
            payload: {
              data: response?.data,
            },
          }),
        ])
      }
    } catch (err) {
      yield all([
        put({
          type: FAILURE(EDITOR_CHANGE_QUIZ_TYPE),
          payload: {
            message: {
              code: __NETWORK_ERROR_TYPE.SAGA_ERROR,
              message: err?.message ?? 'Failed to change quiz type',
            },
          },
        }),
      ])
    }
  })
}

export function * refreshEditor () {
  yield takeEvery(EVENT(EDITOR_REFRESH), function * ({ payload }) {
    yield all([
      put({
        type: REQUEST(EDIT0R_GET_DETAIL_QUIZ),
        payload: {
          quizUuid: payload?.quizUuid,
        },
      }),
    ])
  })
}

export default function * rootSaga () {
  yield all([
    fork(getQuiz),
    fork(getQuestion),
    fork(QuestionChangeType),
    fork(refreshEditor),
  ])
}