import React, { useContext, useEffect, useState } from 'react'
import { Handlers } from './handlers'
import { TimeContext } from './context/time-context'
import { useDispatch, useSelector } from 'react-redux'
import { GAME_FORCE_NEXT_OR_FINISH, GAME_INTERVAL_TIME, GAME_TIME_UP } from '../../../redux/constants/Game'
import { EVENT } from '../../../redux/actions/action.type'
import { GAME_STATUS } from '../../../constants/game.constant'
import { useHistory } from 'react-router-dom'

export function QuestionDuration ({ current }) {
  const ctx = useContext(TimeContext)
  const history = useHistory()
  const _search = new URLSearchParams(history.location.search)
  const { duration, status, running } = useSelector(state => state.Game.game)
  const { minute, second } = Handlers.secondToMMSS(current - duration).formated
  const dispatch = useDispatch()

  let timeout
  useEffect(() => {
    if (running && status !== GAME_STATUS.TIME_UP) {
      if (!_search.get('isDev')) {
        if ((current - ctx?.seconds) >= 0) {
          if ((current - ctx?.seconds) >= 1) {
            dispatch({
              type: EVENT(GAME_INTERVAL_TIME),
              payload: {
                second: ctx?.seconds,
              },
            })
          } else {
            timeout = setTimeout(() => {
              dispatch({
                type: EVENT(GAME_TIME_UP),
                payload: {},
              })
            }, 200)
          }
        }
      }

    }
    return () => clearTimeout(timeout)
  }, [ctx.seconds, running, current])

  useEffect(() => {
    if ((status === GAME_STATUS.TIME_UP)) {
      dispatch({
        type: EVENT(GAME_FORCE_NEXT_OR_FINISH),
        payload: {},
      })
    }
  }, [current, status])

  return (
    <div className={[
      `cr-flex cr-items-center cr-gap-1 cr-min-w-[100px] cr-justify-center cr-py-1 cr-rounded-full`,
      (current - duration) < 10 ? '!cr-bg-rose-100 cr-border cr-border-rose-500' : '!cr-bg-green-100 cr-border cr-border-green-500',
    ].join(' ')}>
      <span className={[
        'cr-text-lg cr-font-bold',
        (current - duration) < 10 ? '!cr-text-rose-600' : '!cr-text-green-600',
      ].join(' ')}>{minute}</span>
      <span className={[
        'cr-text-lg cr-font-bold',
        (current - duration) < 10 ? '!cr-text-rose-600' : '!cr-text-green-600',
      ].join(' ')}>:</span>
      <span className={[
        'cr-text-lg cr-font-bold',
        (current - duration) < 10 ? '!cr-text-rose-600' : '!cr-text-green-600',
      ].join(' ')}>{second}</span>
    </div>
  )
}