import ApiService from '../api.service'

export class QuizSagaService {
  /**
   * @param payload
   * @returns {Promise<unknown>}
   */
  static getQuizzes (payload) {
    return new Promise((resolve) => {
      resolve(new ApiService({
        url: `/api/v1/quiz/`,
        config: {
          params: {
            ...payload?.params,
          },
        },
      }).get())
    })
  }

  /**
   * @param payload
   * @return {Promise<unknown>}
   */
  static getQuiz (payload) {
    return new Promise((resolve) => {
      resolve(new ApiService({
        url: `/api/v1/quiz/${payload?.quizUuid}`,
      }).get())
    })
  }

  /**
   * @param {object} payload
   * @param {string} payload.quizUuid
   * @param {object} payload.params
   * @returns {Promise<unknown>}
   */
  static getQuizOverview (payload) {
    return new Promise((resolve) => {
      resolve(new ApiService({
        url: `/api/v1/quiz/${payload?.quizUuid}/overview`,
        config: {
          params: {
            ...payload?.params,
          },
        },
      }).get())
    })
  }

  /**
   * ## Get Collection Questions
   *
   * @param {object} payload
   * @param {string} payload.quizUuid
   * @param {number} payload.page
   * @param {number} payload.limit
   * @param {Array<{key:string,operator:string,value: any}>} payload.terms
   * @returns {Promise<unknown>}
   */
  static getCollectionQuestions (payload) {
    return new Promise((resolve) => {
      resolve(new ApiService({
        url: `/api/v1/quiz/question`,
        config: {
          params: {
            page: payload?.page ?? 1,
            limit: payload?.limit ?? 10,
            terms: [
              ...payload?.terms,
              {
                key: 'quizUuid',
                operator: 'eq',
                value: payload?.quizUuid,
              },
            ],
          },
        },
      }).get())
    })
  }

  /**
   *
   * @param {object} payload
   * @param {object} payload.uuid
   */
  static getQuestion (payload) {
    return new Promise((resolve) => {
      resolve(new ApiService({
        url: `/api/v1/quiz/question/${payload?.uuid}`,
      }).get())
    })
  }

  /**
   * @param {object} payload
   * @param {object} payload.questionUuid
   * @param {object} payload.quizTypeUuid
   * @returns {Promise<Awaited<ApiService>>}
   * @constructor
   */
  static QuestionChangeType (payload) {
    return Promise.resolve(new ApiService({
      url: `/api/v1/quiz/question/${payload?.questionUuid}/force-type`,
      body: {
        quizTypeUuid: payload?.quizTypeUuid,
      },
    }).put())
  }
}