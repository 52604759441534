import React from 'react'
import { assertClassName } from '../../../utils/core'
import { Layout } from 'antd'
import { FullScreenProvider } from '../../../context/FullScreenContext'
import { WindowFocusProvider } from '../../../context/window-focus'
import PlayLayout from './play-layout'
import styled from 'styled-components'
import { theme as ANTDTheme } from 'antd'
import Utils from '../../../utils'
import { AuthGuard } from './Stepper/03-01-Auth'
import { useSelector } from 'react-redux'
import SettingGame from './setting.game'

const LayoutApp = styled(Layout)`
    background: ${(props) => Utils.shadeColor(props?.token?.colorPrimary, -50)}
`

/**
 * @param {object} props
 * @param {any} props.direction
 * @param {'default' | 'game'} props.type
 * @param {ReactNode} props.children
 * @param props.type
 * @returns {Element}
 */
function PlayApp ({ direction, type = 'default', ...props }) {
  const { isAuth } = useSelector(({ auth }) => auth)
  const { useToken, getDesignToken, defaultSeed } = ANTDTheme
  const { token: theme } = useToken()
  return (
    <React.Fragment>
      <WindowFocusProvider>
        <FullScreenProvider>
          <LayoutApp token={theme}
                     className={assertClassName([
                       'play',
                     ])}
          >
            <Layout className={assertClassName([
              'play-layout',
            ])}>
              <AuthGuard isAuthenticated={isAuth}>
                <PlayLayout />
              </AuthGuard>
            </Layout>
            <SettingGame />
          </LayoutApp>
        </FullScreenProvider>
      </WindowFocusProvider>
    </React.Fragment>
  )
}

export { PlayApp }