import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { LockOutlined, MailOutlined } from '@ant-design/icons'
import { Button, Form, Input, Alert, Segmented } from 'antd'
import { showAuthMessage, showLoading, hideAuthMessage, authenticated } from 'redux/actions/Auth'
import { useHistory } from 'react-router-dom'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import ApiService from '../../../services/api.service'

const rules = {
  email: [
    {
      required: true,
      message: 'Please input your email address',
    },
    {
      type: 'email',
      message: 'Please enter a validate email!',
    },
  ],
  password: [
    {
      required: true,
      message: 'Please input your password',
    },
  ],
  confirm: [
    {
      required: true,
      message: 'Please confirm your password!',
    },
    ({ getFieldValue }) => ({
      validator (rule, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve()
        }
        return Promise.reject('Passwords do not match!')
      },
    }),
  ],
}

/**
 *
 * @param {'AUTH' | 'GAME'}type
 * @param {object | any }props
 * @returns {Element}
 * @constructor
 */
export const RegisterForm = ({ ...props }) => {

  const { showLoading, token, loading, redirect, message, showMessage, hideAuthMessage, allowRedirect } = props
  const [form] = Form.useForm()

  const onSignUp = () => {
    form.validateFields().then(values => {

      if (typeof (props?.beforeSubmit) !== 'undefined' && typeof (props?.beforeSubmit) === 'function') {
        props.beforeSubmit(values)
      }
    }).catch(info => {

    })
  }
  return (
    <>
      <motion.div
        initial={{ opacity: 0, marginBottom: 0 }}
        animate={{
          opacity: showMessage ? 1 : 0,
          marginBottom: showMessage ? 20 : 0,
        }}>
        <Alert type="error" showIcon message={message}></Alert>
      </motion.div>
      <Form
        form={form}
        layout="vertical"
        name="register-form"
        initialValues={{
          ...props?.initialValues,
        }}
        onFinish={onSignUp}>
        {
          props?.addonBefore
        }
        <Form.Item
          name="email"
          label="Email"
          rules={rules.email}
          hasFeedback
        >
          <Input placeholder={'input your email'} prefix={<MailOutlined className="text-primary" />} />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={rules.password}
          hasFeedback
        >
          <Input.Password placeholder={'input your password'} prefix={<LockOutlined className="text-primary" />} />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="ConfirmPassword"
          rules={rules.confirm}
          hasFeedback
        >
          <Input.Password placeholder={'Input your confirm password'}
                          prefix={<LockOutlined className="text-primary" />} />
        </Form.Item>
        {
          props?.addonAfter
        }
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Sign Up
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

RegisterForm.propTypes = {
  mode: PropTypes.oneOf(['default', 'game', null, undefined]),
  addonBefore: PropTypes.node,
  addonAfter: PropTypes.node,
  beforeSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
}

const mapStateToProps = ({ auth }) => {
  const { loading, message, showMessage, token, redirect } = auth
  return { loading, message, showMessage, token, redirect }
}

const mapDispatchToProps = {
  showAuthMessage,
  hideAuthMessage,
  showLoading,
  authenticated,
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm)
