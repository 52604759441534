/**
 * @type {{QA: string, SUPER: string, UNVERIFIED: string, USER_PARTNER: string, GUEST: string, MANAGER: string, ADMIN: string, USER: string}}
 */
export const __ROLE__ = {
  SUPER: 'super',
  MANAGER: 'manager',
  ADMIN: 'admin',
  TEACHER: 'user:teacher',
  STUDENT: 'user:student',
  USER: 'user',
  USER_PARTNER: 'user:partner',
  QA: 'quality-assurance',
  GUEST: 'guest',
  UNVERIFIED: 'unverified',
}