import axios from 'axios'
import { GetHost } from '../../utils/get-host'
import { Handler } from '../../utils/handler'
import { notification } from 'antd'
import { AUTH_TOKEN } from '../../redux/constants/Auth'
import history from '../../history'

const { api } = new GetHost().getUrl()
const instance = axios.create({
  baseURL: api,
  timeout: 10000,
})

instance.interceptors.request.use((config) => {
  let _token = Handler.token('get')
  if (_token) {
    Reflect.set(config.headers, 'Authorization', `Bearer ${_token}`)
  }
  return config
}, (error) => {
  Promise.reject(error)
})

instance.interceptors.response.use((response) => {
  return response?.data
}, (error) => {

  let notificationParam = {
    message: '',
  }

  // Remove token and redirect
  if (error.response.status === 400 || error.response.status === 403) {
    notificationParam.message = 'Authentication Fail'
    notificationParam.description = 'Please login again'
    Handler.token('remove')
  }

  if (error.response.status === 404) {
    notificationParam.message = 'Not Found'
  }

  if (error.response.status === 500) {
    notificationParam.message = 'Internal Server Error'
  }

  if (error.response.status === 508) {
    notificationParam.message = 'Time Out'
  }

  return Promise.reject(error)
})

export default instance