import { takeEvery, fork, call, put, all, delay } from 'redux-saga/effects'
import { FAILURE, REQUEST, SUCCESS } from '../actions/action.type'
import { __NETWORK_ERROR_TYPE } from '../../constants/network.constant'
import { SITE_ALL_CONFIG, UPDATE_SITE_APP } from '../constants/Site'
import { OptionsSagaService } from '../../services/saga/options.saga.service'
import { AUTH_LOAD_USER } from '../constants/Auth'
import ApiService from '../../services/api.service'
import { message } from 'antd'

export function * _getConfig () {
  yield takeEvery(REQUEST(SITE_ALL_CONFIG), function * ({ payload }) {
    try {
      const response = yield call(OptionsSagaService.getOptions, {
        params: {
          ...payload?.params,
          limit: payload?.params ?? 100,
        },
      })

      if (!response?.error) {

        yield put({
          type: SUCCESS(SITE_ALL_CONFIG),
          payload: {
            ...response,
            data: response?.data,
          },
        })

        yield delay(200)
        yield all([
          put({
            type: REQUEST(AUTH_LOAD_USER),
          }),
        ])
      } else {
        yield put({
          type: FAILURE(SITE_ALL_CONFIG),
          payload: {
            message: {
              code: __NETWORK_ERROR_TYPE.SAGA_NOTFOUND,
              description: 'URL could not be found, please use the correct URL',
            },
            error: true,
          },
        })
      }
    } catch (err) {
      yield all([
        put({
          type: FAILURE(SITE_ALL_CONFIG),
          payload: {
            message: err,
            error: true,
          },
        }),
      ])
    }
  })
}

export function * updateSiteApp () {
  yield takeEvery(REQUEST(UPDATE_SITE_APP), function * ({ payload }) {
    try {
      const response = yield call(() => {
        return new Promise((resolve) => {
          resolve(new ApiService({
            url: `/api/v1/options`,
            body: payload?.dto,
          })
            .post())
        })
      })
      if (!response?.error) {
        let _newData = {
          ...response?.data,
        }
        if (typeof (payload?.onSuccess) !== 'undefined' && payload?.onSuccess instanceof Function) {
          payload.onSuccess(response?.message)
        }
        if (response?.data?.opt_value && typeof (response?.data?.opt_value) === 'string') {
          try {
            Reflect.set(_newData, 'opt_value', JSON.parse(response?.data?.opt_value))
          } catch (e) {

          }
        }
        yield all([
          put({
            type: SUCCESS(UPDATE_SITE_APP),
            payload: _newData,
          }),
        ])
      } else {
        if (typeof (payload?.onError) !== 'undefined' && payload?.onError instanceof Function) {
          payload.onError(response?.message)
        }
        yield all([
          put({
            type: FAILURE(UPDATE_SITE_APP),
            payload: response,
          }),
        ])
      }
    } catch (err) {
      yield put({
        type: FAILURE(UPDATE_SITE_APP),
        payload: {},
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(updateSiteApp),
    fork(_getConfig),
  ])
}