import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import AppLayout from 'layouts/app-layout'
import AuthLayout from 'layouts/auth-layout'
import AppLocale from 'lang'
import { IntlProvider } from 'react-intl'
import { ConfigProvider } from 'antd'
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass'
import { THEME } from '../configs/ThemeConfig'
import NotFoundPage from '../components/layout-components/not-found.page'
import LoadUser from '../app/load-user'
import RouteInterceptor from '../guard/interceptor.route'
import AppEditor from './editor-views/components/app.editor'
import { PlayApp } from './play-views/components'
import SiteProvider from '../app/site.provider'

export const Views = (props) => {
  const { locale, location, isAuth, loading, direction } = props
  const currentAppLocale = AppLocale[locale]
  useBodyClass(`dir-${direction}`)

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider
        theme={{
          ...THEME,
//          cssVar: true,
          cssVar: true,
        }} locale={currentAppLocale.antd}
        direction={direction}
      >
        <SiteProvider>
          <Switch>
            <Route path={'/play'}>
              <PlayApp direction={direction} location={location} />
            </Route>
            <Route path={AUTH_PREFIX_PATH}>
              <AuthLayout direction={direction} />
            </Route>
            <RouteInterceptor path={'/editor'} isAuthenticated={isAuth} loading={loading}>
              <AppEditor direction={direction} location={location} />
            </RouteInterceptor>

            <RouteInterceptor path={'/'} isAuthenticated={isAuth} loading={loading}>
              <AppLayout direction={direction} location={location} />
            </RouteInterceptor>
            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </SiteProvider>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme
  const { token, isAuth, loading } = auth
  return { locale, token, direction, isAuth, loading }
}

export default withRouter(connect(mapStateToProps)(Views))