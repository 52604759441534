import { __APP_STORAGE__ } from '../constants/app.constant'
import { last } from 'lodash/array'

export class Handler {
  static queryToObj (obj) {
    let _Params = {}
    if (typeof (window.location) !== 'undefined' && typeof (window.location.search) !== 'undefined') {
      const search = window.location.search.substring(1)
      _Params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
    }
    if (Object.keys(_Params).length > 0) {
      Object.entries(_Params).map(([key, value]) => {
        Reflect.set(obj, key, value)
      })
    }
    if (process.env.REACT_APP_ENV === 'PRD') {
      Reflect.set(obj, 'state', 'LIVE')
    } else {
      Reflect.set(obj, 'state', 'default')
    }
    return obj
  }

  /**
   * @param {'set' | 'remove' | 'get' } type
   * @param {object} opt
   * @param {any} opt.value
   * @returns {string|boolean|null}
   */
  static token (type = 'get', opt) {
    if (!localStorage) return null

    try {
      switch (type) {
        case 'set':
          localStorage.setItem(__APP_STORAGE__.AUTH_TOKEN, opt?.value)
          return true
        case 'get':
          if (localStorage.getItem(__APP_STORAGE__.AUTH_TOKEN)) {
            return localStorage.getItem(__APP_STORAGE__.AUTH_TOKEN)
          }
          return null
        case 'remove':
          if (localStorage.getItem(__APP_STORAGE__.AUTH_TOKEN)) {
            localStorage.removeItem(__APP_STORAGE__.AUTH_TOKEN)
            return true
          }
          return true
        default:
          if (localStorage.getItem(__APP_STORAGE__.AUTH_TOKEN)) {
            return localStorage.getItem(__APP_STORAGE__.AUTH_TOKEN)
          }
          return null

      }

    } catch (err) {
      return null
    }

  }

  /**
   * @param {Object.<string,string|number|boolean>} obj
   * @param {boolean} usingDispatch
   * @constructor
   */
  static PushQS (obj, usingDispatch = true) {
    const search = new URLSearchParams(window.location.search)
    Object.entries(obj).map(([key, value]) => {
      if (typeof (search.get(key)) !== 'undefined' && search.get(key) !== null && search.get(key).length >= 0) {
        search.set(key, value)
      } else {
        search.append(key, value)
      }
    })

    let _url = [window.location.pathname, search.toString()].join('?')
    window.history.pushState(obj, 'push-query-params', _url)
    window.dispatchEvent(new PopStateEvent('popState'))

  }

  /**
   *
   * @param {string | Array<string>}str
   * @constructor
   */
  static UnShiftQS (str) {
    const search = new URLSearchParams(window.location.search)

    if (Array.isArray(str) && str.length > 0) {
      str.map((child) => {
        if (search.get(child)) {
          search.delete(child)
        }
      })
    } else if (typeof (str) === 'string') {
      if (search.get(str)) {
        search.delete(str)
      }
    }

    let _url = [window.location.pathname, search.toString()].join('?')
    window.history.pushState(null, 'push-query-params', _url)
  }

  static getMediaSet (url) {
    return url
    if (!url || `${url}`.length === 0) return url
    let _ = [url]
    let set = [
      '-400-lazy',
      '-400-overview',
    ]
    set.map((child) => {
      let _split = url.split('.')
      let start = _split.slice(0, _split.length - 2)
      let end = last(_split)
      _.push([start, child, '.', end].join(''))
    })
    return _
  }
}