import ApiService from '../api.service'
import instance from './instance'
import history from '../../history'
import { Handler } from '../../utils/handler'

export class AuthService extends ApiService {
  constructor (props) {
    super(props)
    this.http = instance
  }

  async loadUser () {
    try {
      this.url = `/api/v1/auth/load-user`
      return await this.get()
        .then((response) => {
          return response
        })
    } catch (err) {
      history.replace('/auth/login')
      window.location.reload()
      return err
    }
  }

  async login () {
    try {
      this.url = `/api/v1/auth/login`
      const response = await this.post()
      if (response?.token) {
        Handler.token('set', { value: response?.token })
        history.push(`/`)
        window.location.reload()
      }
      return response
    } catch (err) {
      history.replace('/auth/login')
      window.location.reload()
    }
  }
}