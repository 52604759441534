import { FAILURE, REQUEST, SUCCESS } from '../actions/action.type'
import { SITE_ALL_CONFIG, UPDATE_SITE_APP } from '../constants/Site'

const initialState = {
  data: {},
  collections: {
    loading: true,
    data: [],
    pagination: {
      page: 1,
      limit: 10,
    },
  },
}

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FAILURE(SITE_ALL_CONFIG):
      return {
        ...state,
        collections: {
          ...state.collections,
          loading: false,
          data: [],
          pagination: {},
        },
      }
    case REQUEST(SITE_ALL_CONFIG):
      return {
        ...state,
        collections: {
          ...state.collections,
          loading: true,
          data: [],
          pagination: {},
        },
      }
    case SUCCESS(SITE_ALL_CONFIG):
      if (Array.isArray(payload?.data) && payload?.data.length > 0) {
        payload?.data.map((child) => {
          Reflect.set(state.data, child?.opt_name, child)
        })
      }

      return {
        ...state,
        data: state.data,
        collections: {
          ...state.collections,
          loading: false,
          data: [...payload?.data],
          pagination: { ...payload?.pagination },
        },
      }

    case REQUEST(UPDATE_SITE_APP):
      return {
        ...state,
      }
    case SUCCESS(UPDATE_SITE_APP):
      if (
        state?.data instanceof Object &&
        Object.keys(state?.data).length > 0 &&
        state?.data?.site_app instanceof Object &&
        Object.keys(state?.data?.site_app).length > 0
      ) {
        Reflect.set(state.data, 'site_app', payload)
      }
      return {
        ...state,
        data: {
          ...state?.data,
        },
      }
    case FAILURE(UPDATE_SITE_APP):

    default:
      return state
  }
}