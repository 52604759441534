import { REQUEST } from './action.type'
import { SITE_ALL_CONFIG, UPDATE_SITE_APP } from '../constants/Site'

export function GetAllSiteConfiguration () {
  return {
    type: REQUEST(SITE_ALL_CONFIG),
  }
}

export function UpdateSiteApp (payload) {
  return {
    type: REQUEST(UPDATE_SITE_APP),
    payload,
  }
}