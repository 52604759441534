export const initialState = {
  settings: {
    audio: {
      isPlay: false,
      isPaused: false,
      isStopped: false,
      src: '/music/instrument-game.mp3',
    },
  },
}

export default function (state = initialState, { type, payload }) {
  switch (type) {
    default:
      return state
  }
}