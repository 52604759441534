import {
  AUTH_TOKEN,
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  AUTH_LOAD_USER, SIGNOUT, USER_CHANGE_AVATAR, USER_CHANGE_PROFILE,
} from '../constants/Auth'
import { FAILURE, REQUEST, SUCCESS } from '../actions/action.type'

const initState = {
  loading: true,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN),
  error: null,
  isSuccess: false,
  isAuth: false,

  user: {
    loadingAvatar: false,
    permissions: [],
    role: null,
    data: null,
  },
  collections: {},
}

const auth = (state = initState, action) => {
  let { payload } = action
  switch (action.type) {
    case REQUEST(AUTH_LOAD_USER):
      return {
        ...state,
        user: {
          ...state.user,
          data: null,
          role: null,
          permissions: [],
        },
        error: null,
        loading: true,
        isAuth: false,
      }
    case FAILURE(AUTH_LOAD_USER):
      return {
        ...state,
        user: {
          ...state.user,
          data: null,
          role: null,
          permissions: [],
        },
        error: action?.payload?.error ?? null,
        isAuth: false,
        loading: false,
      }
    case SUCCESS(AUTH_LOAD_USER):
      return {
        ...state,
        user: {
          ...state.user,
          ...action?.payload,
        },
        error: null,
        loading: false,
        isAuth: true,
      }

    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        redirect: '/',
        token: action.token,
      }
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      }
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: '',
        showMessage: false,
      }
    case SIGNOUT:
      return {
        ...state,
        loading: true,
      }
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        isAuth: false,
        token: null,
        redirect: '/',
        loading: false,
      }
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.token,
      }
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case REQUEST(USER_CHANGE_AVATAR):
      return {
        ...state,
        user: {
          ...state.user,
          loadingAvatar: true,
        },
      }
    case SUCCESS(USER_CHANGE_AVATAR):
      if (
        state.user.data !== null &&
        typeof (state.user.data) !== 'undefined'
      ) {
        state.user.data = {
          ...state.user.data,
          ...payload,
        }
      }
      return {
        ...state,
        user: {
          ...state.user,
          loadingAvatar: false,
        },
      }
    case FAILURE(USER_CHANGE_AVATAR):
      return {
        ...state,
        user: {
          ...state.user,
          loadingAvatar: false,
        },
      }

    case REQUEST(USER_CHANGE_PROFILE):
      return {
        ...state,
        user: {
          ...state.user,
          loadingAvatar: true,
        },
      }
    case SUCCESS(USER_CHANGE_PROFILE):
      if (
        state.user.data !== null &&
        typeof (state.user.data) !== 'undefined'
      ) {
        state.user.data = {
          ...state.user.data,
          ...payload,
        }
      }
      return {
        ...state,
        user: {
          ...state.user,
          loadingAvatar: false,
        },
      }
    case FAILURE(USER_CHANGE_PROFILE):
      return {
        ...state,
        user: {
          ...state.user,
          loadingAvatar: false,
        },
      }

    default:
      return state
  }
}

export default auth