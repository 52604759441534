import React from 'react'
import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH, NAV_TYPE_TOP } from 'constants/ThemeConstant'
import { APP_NAME } from 'configs/AppConfig'
import { connect } from 'react-redux'
import utils from 'utils'
import { Flex, Grid, Typography } from 'antd'
import Utils from 'utils'

const { useBreakpoint } = Grid

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props
  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  if (isMobile && !props.mobileLogo) {
    return 0
  }
  if (isNavTop) {
    return 'auto'
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`
  } else {
    return `${SIDE_NAV_WIDTH}px`
  }
}

const getLogo = (props) => {
  const { navCollapsed, logoType, logo } = props
  if (logoType === 'light') {
    if (navCollapsed) {
      return logo?.small ?? '/img/logo-sm-white.png'
    }
    return logo?.large ?? '/img/logo-white.png'
  }

  if (navCollapsed) {
    return logo?.small ?? '/img/logo-sm.png'
  }
  return logo?.large ?? '/img/logo.png'
}

const getLogoDisplay = (isMobile, mobileLogo) => {
  if (isMobile && !mobileLogo) {
    return 'd-none'
  } else {
    return 'logo'
  }
}

export const Logo = ({ logo, opt_value, ...props }) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')

  return (<div
    className={[getLogoDisplay(isMobile, props.mobileLogo),
      !isMobile && props?.navCollapsed ? '!cr-flex !cr-justify-center' : '',
    ].join(' ')}
    style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}>
    {isMobile ? opt_value?.used === 'text' ? Utils.getNameInitial(opt_value?.name) : opt_value?.used === 'mix' ? <Flex
      gap={10} align={'center'}>
      <img src={opt_value?.logo?.small} alt={`${APP_NAME} logo`}
           className={'!cr-h-[40px] !cr-object-contain'}
      />
      <span>{Utils.getNameInitial(opt_value?.name)}</span>
    </Flex> : <img src={opt_value?.logo?.small} alt={`${APP_NAME} logo`}
                   className={'!cr-h-[40px] !cr-object-contain'}
    /> : opt_value?.used === 'text' ? <span>{opt_value?.name}</span> : opt_value?.used === 'mix' ? <Flex gap={10}
                                                                                                         align={'center'}>
      <img src={opt_value?.logo?.small} alt={`${APP_NAME} logo`}
           className={'!cr-h-[40px] !cr-min-w-[40px] !cr-object-contain'}
      />
      {
        !props?.navCollapsed && (
          <Typography.Title
            level={1}
            className={'!cr-m-0 !cr-p-0 !cr-text-lg !cr-whitespace-nowrap'}>{opt_value?.name}</Typography.Title>
        )
      }

    </Flex> : <img src={opt_value?.logo?.small} alt={`${APP_NAME} logo`}
                   className={'!cr-h-[40px] !cr-object-contain'}
    />}
  </div>)
}

const mapStateToProps = ({ theme, Sites }) => {
  const { navCollapsed, navType } = theme
  let { data } = Sites
  let { site_app } = data
  let { logo } = site_app?.opt_value
  return { navCollapsed, navType, logo, opt_value: site_app?.opt_value ?? {} }
}

export default connect(mapStateToProps)(Logo)
