import React, { createContext, useEffect, useState } from 'react'

export const WindowFocusContext = createContext({})

const WindowFocusProvider = ({ children }) => {
  const [state, setState] = useState({
    status: 'focus',
  })

  const onFocus = () => {
    setState((prevState) => ({ ...prevState, status: 'focus' }))
  }

  const offFocus = () => {
    setState((prevState) => ({ ...prevState, status: 'blur' }))

  }

  const onBlur = () => {
    setState((prevState) => ({ ...prevState, status: 'blur' }))
  }

  React.useEffect(() => {
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)

    return () => {
      window.removeEventListener('focus', offFocus)
      window.removeEventListener('blur', onBlur)
    }
  }, [])
  return (
    <WindowFocusContext.Provider value={{
      state,
      setState,
    }}>
      {children}
    </WindowFocusContext.Provider>
  )
}

export { WindowFocusProvider }