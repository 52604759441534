import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { SketchPicker, TwitterPicker } from 'react-color'
import { __TW_COLOR } from '../../../configs/tailwind.base'

const ColorPicker = props => {

  const { colorChange, color = '' } = props

  const [visible, setVisible] = useState(false)
  const [pickerColor, setPickerColor] = useState(color)
  const [boxColor, setBoxColor] = useState(color)

  useEffect(() => {
    setBoxColor(color)
    setPickerColor(color)
  }, [color])

  const onPickerDropdown = () => {
    setVisible(!visible)
  }

  const onColorChange = (value) => {
    const { rgb } = value
    const rgba = `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
    setBoxColor(rgba)
    setPickerColor(value)
    if (typeof (colorChange) !== 'undefined' && typeof (colorChange) === 'function') {
      colorChange(value.hex)
    }

  }

  return (
    <div className="color-picker !cr-w-full">
      <div className="color-picker-dropdown" style={{ ...props?.style }}>
        <div className="color"
             style={{
               height: '100%',
               width: '100%',
               borderRadius: '7px',
               backgroundColor: boxColor ? boxColor : '#ffffff',
             }}
             onClick={onPickerDropdown} />
      </div>
      {
        visible && (
          <>
            <div className="color-picker-backdrop" onClick={onPickerDropdown} />
            <div className={'sketch-picker'}>
              <div className={'cr-bg-white cr-p-2 cr-rounded-lg cr-absolute cr-top-0 cr-w-full'}>
                <TwitterPicker
                  styles={{
                    card: {
                      minWidth: '100%',
                    },
                    body: {
                      width: '100%',
                    },
                    triangleShadow: {
                      border: '1px solid red',
                    },
                    triangle: {
                      border: '1px solid red',
                    },
                    input: {
                      border: '1px solid red',
                    },
                  }}
                  colors={__TW_COLOR.map((color) => color?.value)} color={pickerColor} onChange={onColorChange} />
              </div>
            </div>
          </>
        )
      }
    </div>
  )
}

ColorPicker.propTypes = {
  color: PropTypes.string,
  colorChange: PropTypes.func,
}

export default ColorPicker
