import ApiService from '../api.service'
import { AnalyticsSagaService } from './analytics.saga.service'

export class TeacherSagaService extends AnalyticsSagaService {
  static API = ApiService

  /**
   * @param {object} payload
   * @param {object} payload.params
   * @param {number} payload.params.page
   * @param {number} payload.params.limit
   */
  static getMyLibrary (payload) {
    return new Promise((resolve) => {
      resolve(new TeacherSagaService.API({
        url: `/api/v1/me/quizzes`,
        config: {
          params: {
            ...payload?.params,
          },
        },
      }).get())
    })
  }

  /**
   * @param {object} payload
   * @param {object} payload.params
   * @param {boolean | string | 'true' | 'false' | true|false} payload.params.showAll
   */
  static getAnalytics (payload) {
    return new Promise((resolve) => {
      resolve(new TeacherSagaService.API({
        url: `/api/v1/quiz/analytics`,
        config: {
          ...payload,
        },
      }).get())
    })
  }
}