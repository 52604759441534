import { combineReducers } from 'redux'
import Auth from './Auth'
import Analytics from './Analytics'
import Theme from './Theme'
import Editor from './Editor'
import Quiz from './Quiz'
import Display from './Display'
import Game from './Game'
import Teacher from './Teacher'
import Sites from './Sites'
import { analyticsPersistConfig, gamePersistConfig, QuizPersistConfig } from '../store/persist'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const reducers = combineReducers({
  Analytics: persistReducer(analyticsPersistConfig, Analytics),
  theme: Theme,
  auth: Auth,
  editor: Editor,
  Quiz: persistReducer(QuizPersistConfig, Quiz),
  Display,
  Teacher: Teacher,
  Game: persistReducer(gamePersistConfig, Game),
  Sites: persistReducer({
    key: 'sites',
    storage,
    blacklist: [
      'collections',
    ],
  }, Sites),
})

export default reducers