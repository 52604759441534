import { all, fork, put, call, takeEvery } from 'redux-saga/effects'
import { FAILURE, REQUEST, SUCCESS } from '../actions/action.type'
import { TEACHER_LIBRARY, TEACHER_LIBRARY_ANALYTICS } from '../constants/Teacher'
import { TeacherSagaService } from '../../services/saga/teacher.saga.service'

export function * getAnalytics () {
  yield takeEvery(REQUEST(TEACHER_LIBRARY_ANALYTICS), function * ({ payload }) {
    try {
      const response = yield call(TeacherSagaService.getAverageUser, { params: payload?.params })
      if (!response?.error) {
        yield put({
          type: SUCCESS(TEACHER_LIBRARY_ANALYTICS),
          payload: {
            state: payload?.state,
            data: response?.data,
            pagination: response?.pagination,
          },
        })
      } else {
        yield put({
          type: FAILURE(TEACHER_LIBRARY_ANALYTICS),
          payload: {
            ...response,
          },
        })
      }
    } catch (err) {
      yield put({
        type: FAILURE(TEACHER_LIBRARY_ANALYTICS),
        payload: {
          ...err,
        },
      })
    }
  })
}

export function * getMyLibrary () {
  yield takeEvery(REQUEST(TEACHER_LIBRARY), function * ({ payload }) {
    try {
      const response = yield call(TeacherSagaService.getMyLibrary, payload)

      if (!response?.error) {
        yield put({
          type: SUCCESS(TEACHER_LIBRARY),
          payload: {
            ...response,
          },
        })
      } else {
        yield put({
          type: FAILURE(TEACHER_LIBRARY),
          payload: {},
        })
      }
    } catch (err) {
      yield all([
        put({
          type: FAILURE(TEACHER_LIBRARY),
          payload: err,
        }),
      ])
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getAnalytics),
    fork(getMyLibrary),
  ])
}