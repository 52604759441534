export const AUDIO_ACTION_TYPE = {
  PLAY: 'PLAY',
  CHANGE_VOLUME: 'CHANGE_VOLUME',
  PAUSE: 'PAUSE',
  STOP: 'STOP',
}

export const AUDIO_STATUS = {
  PLAY: "play",
  PAUSE: 'pause',
  STOP: 'stop'
}
