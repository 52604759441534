export const GAME_BUMPER_TYPE = {
  OPENING: 'opening',
  NEXT_QUESTION: 'next-question',
}

export const GAME_STATUS = {
  PROGRESS: 'progress',
  COMPLETED: 'completed',
  DONE: 'done',
  PAUSE: 'pause',

  QUEUE: 'queue',

  FORCE: 'force-next',
  FORCE_SUBMIT: 'force-submit',
  SUBMIT: 'submit',
  TIME_UP: 'time-up',
  CHECK_POINT: 'check-point',
}