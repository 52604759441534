import { message } from 'antd'
import { QUIZ_HINTS_SEPARATOR } from '../../../../constants/quiz-type.constant'

export class Handlers {
  static key = 'handler-submit'

  /**
   *
   * @param {Array<any>}data
   */
  static calcTotalScore (data) {
    if (Array.isArray(data) && data.length > 0) {
      return data.map((child) => child?.points).reduce((a, b) => a + b)
    }
    return 0
  }

  static error (err) {
    console.log({ err }, 'HANDLER ERROR')
    switch (err?.values?.type) {
      case 'multiple-choice':
        Handlers._errMultipleChoice(err?.values?.data?.type)
        break
      default:
        break
    }
  }

  static _errMultipleChoice (choiceType) {
    if (typeof (choiceType) !== 'undefined') {
      if (choiceType === 'multiple') {
        message.error({
          key: Handlers.key,
          content: 'Pilih minimal 1',
        }, 5).then(r => r)
      } else if (choiceType === 'single') {
        message.error({
          key: Handlers.key,
          content: 'Pilih salah satu',
        }, 5).then(r => r)
      }
    } else {

    }
  }

  static secondToMMSS (second) {
    const minutes = Math.floor(second / 60)
    const seconds = second % 60
    const formattedMinutes = String(minutes).padStart(2, '0')
    const formattedSeconds = String(seconds).padStart(2, '0')
    return {
      second,
      seconds,
      minutes,
      formated: {
        minute: formattedMinutes,
        second: formattedSeconds,
      },
    }
  }

  /**
   * @param {Array<PropsCalculateCorrect|any>}state
   * @return {Array<{correct: boolean ,data: Array<any>, type: string, points:number}>}
   */
  static calculateCorrectAnswer (state) {
    const data = state.map((child) => {
      let _ = {
        type: child?.type,
        correct: false,
        points: 0,
        data: child,
      }
      switch (child?.type) {
        case 'fill-in-the-blank':
          Handlers._calculateFillInTheBlankItem(child, _)
          break
        case 'multiple-choice':
          Handlers._calculateMultipleChoice(child, _)
          break
        case 'true-or-false':
          Handlers._calculateTrueOrFalse(child, _)
          break
        default:
          break
      }
      return _
    })
    console.log({ data })
    return data
  }

  static _calculateFillInTheBlankItem (item, obj) {
    if (Array.isArray(item?.data?.answer) && item?.data?.answer.length > 0) {
      let _ = item?.data?.answer.filter((child) => child?.type !== QUIZ_HINTS_SEPARATOR)

      if (Array.isArray(_) && _.length > 0) {
        let _calcCorrect = _.every((child) => `${child?.value}`.toLowerCase() === `${child?.answer}`.toLowerCase())
        Reflect.set(obj, 'points', _calcCorrect ? item?.data?.points : 0)
        Reflect.set(obj, 'correct', _calcCorrect)
      }
    }
  }

  static _calculateMultipleChoice (item, obj) {
    let _points = 0
    let correct = false
    let _type = item?.data?.type
    if (_type === 'multiple') {
      let pointOfItem = item?.data?.points / item?.data.answer.filter((child) => child?.isChecked === true).length
      let _countChecked = item?.data?.answer.filter((child) => child?.isChecked === true).length
      let _calculateCorrect = item?.data?.answer.filter((child) => child?.answer === true && child?.isChecked === true).length

      _points = _calculateCorrect * pointOfItem
      correct = _calculateCorrect > 0
      console.log({ correct, _points })
    } else {
      let _itemCorrect = item?.data?.answer.filter((child) => child?.isChecked === true && child?.answer === true).length === 1
      _points = _itemCorrect ? item?.data?.points : 0
      correct = _itemCorrect
    }
    Reflect.set(obj, 'points', _points)
    Reflect.set(obj, 'correct', correct)
  }

  static _calculateTrueOrFalse (item, obj) {
    let _points = 0
    let correct = false
    let _itemCorrect = item?.data?.answer.filter((child) => child?.isChecked === true && child?.answer === true).length === 1
    _points = _itemCorrect ? item?.data?.points : 0
    correct = _itemCorrect
    Reflect.set(obj, 'points', _points)
    Reflect.set(obj, 'correct', correct)
  }

}

/**
 * @typedef PropsCalculateCorrect
 * @property { number } duration
 * @property {{ status: string }} game
 * @property {string} questionUuid
 * @property {string} quizUuid
 * @property {'multiple-choice' | 'true-or-false' | 'fill-in-the-blank'} type
 */