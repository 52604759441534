export class DotNotation {
  static get (obj, key, options = { defaultValue: undefined, separator: '.' }) {
    const keys = key.split(options?.separator ?? '.')
    if (Array.isArray(keys) && keys.length > 1) {
      let _result = obj ?? {}
      for (const _key of keys) {

        if (_result === undefined || _result === null) {
          return options?.defaultValue ?? undefined
        }
        _result = _result[key]
      }
      return _result !== undefined ? _result : options?.defaultValue ?? undefined
    }
    return null
  }

  static set (obj, key, options = {}) {
    const _keys = key.split(options?.separator ?? '.')
    let _current = obj ?? {}
    if (_keys.length > 1) {
      for (let i = 0; i < _keys.length; i++) {
        let _key = key[i]
        if (i === _key.length - 1) {
          _current[key] = options?.defaultValue ?? undefined
        } else {
          if (typeof _current[key] === 'undefined') {
            _current[key] = {}
          }
          _current = _current[key]
        }
      }
    }
  }

  static convert (obj, key, options = {}) {
    const keys = key.split('.')

    if (Array.isArray(keys) && keys.length > 1) {
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]

        // Jika sudah di akhir, set nilainya
        if (i === keys.length - 1) {
          obj[key] = options?.defaultValue ?? undefined
        } else {
          // Jika belum ada objek, buat objek baru
          if (!obj[key]) {
            obj[key] = {}
          }
          obj = obj[key]
        }
      }
    }
    return obj
  }
}
