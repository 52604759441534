import { EVENT, REQUEST } from './action.type'
import {
  GAME_CHANGE_STATUS_AUDIO,
  GAME_CHANGE_VOLUME,
  GAME_FORCE_SUBMIT, GAME_SEND_RESULT,
  GAME_START, GAME_SUBMIT_QUESTION,
  GET_GAME,
  GAME_SETTING_OPEN,
  GET_QUESTION_GAME,
} from '../constants/Game'

export const GetGame = (payload) => ({
  type: REQUEST(GET_GAME),
  payload,
})

/**
 * @param {object} payload
 * @param {string} payload.quizUuid
 * @returns {{payload, type: string}}
 * @constructor
 */
export const GetQuizGame = (payload) => {
  return {
    type: REQUEST(GET_QUESTION_GAME),
    payload,
  }
}

export const StartGame = () => ({
  type: EVENT(GAME_START),
})

/**
 *
 * @param {object} payload
 * @param {object} payload.dto
 * @returns {{payload, type: string}}
 */
export function ForceNextQuestion (payload) {
  return {
    type: EVENT(GAME_FORCE_SUBMIT),
    payload,
  }
}

export function GameSubmitQuestion (payload) {
  return {
    type: EVENT(GAME_SUBMIT_QUESTION),
    payload,
  }
}

/**
 * @param {object} payload
 * @param {Array<any>} payload.data
 * @param {number} payload.scores
 * @param {number} payload.total_duration
 * @param {number} payload.correct
 * @param {number} payload.incorrect
 * @param {string} payload.status
 * @param {string} payload.quizUuid
 * @return {{payload, type: string}}
 */
export function GameResultSendAPI (payload) {
  return {
    type: REQUEST(GAME_SEND_RESULT),
    payload,
  }
}

/**
 * @param {number} payload
 * @return {{payload, type: string}}
 */
export function GameChangeVolume (payload) {
  return {
    type: EVENT(GAME_CHANGE_VOLUME),
    payload,
  }
}

/**
 * @param {string} payload
 * @return {{payload, type: string}}
 */
export function GameAudioStatus (payload) {
  return {
    type: EVENT(GAME_CHANGE_STATUS_AUDIO),
    payload: payload,
  }
}

/**
 * @param {boolean} payload
 * @return {{payload, type: string}}
 */
export function GameSettingOpen (payload) {
  return {
    type: EVENT(GAME_SETTING_OPEN),
    payload,
  }
}