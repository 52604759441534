import { all } from 'redux-saga/effects'
import Auth from './Auth'
import Editor from './Editor'
import Quiz from './Quiz'
import Game from './Game'
import Teacher from './Teacher'
import Analytics from './Analytics'
import Site from './Site'

export default function * rootSaga (getState) {
  yield all([
    Auth(),
    Editor(),
    Quiz(),
    Game(),
    Teacher(),
    Analytics(),
    Site(),
  ])
}
