import React, { createContext, useEffect } from 'react'

export const FullScreenContext = createContext({})
export const FullScreenProvider = ({ children }) => {
  const [state, setState] = React.useState({
    isActive: false,
  })

  function exit () {
    document.exitFullscreen()
  }

  const enter = () => {
    document.body.requestFullscreen()
  }

  useEffect(() => {
    if (document) {
      document.onfullscreenchange = function (val) {
        setState({ isActive: !state.isActive })
      }
    }
  }, [document.fullscreenEnabled, state.isActive])

  return (
    <FullScreenContext.Provider value={{
      state,
      enter,
      exit,
    }}>
      {children}
    </FullScreenContext.Provider>
  )
}
