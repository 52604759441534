import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { __ROLE__ } from '../../constants/rbac.constant'
import { Button, Flex, Form, Input, message, Modal } from 'antd'
import ApiService from '../../services/api.service'

function NavQuizCode () {
  const { auth } = useSelector((state) => state)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = React.useState(false)
  const [game, setGame] = useState(null)
  const [FormQuizCode] = Form.useForm()

  function _onFinishCode () {
    FormQuizCode.validateFields()
      .then((values) => {
        setLoading(true)
        message.loading({
          key: 'search-quiz',
          content: 'Loading...',
        }, 10)
          .then(r => r)
        new ApiService({
          url: `/api/v1/quiz/${values?.code}`,
          config: {
            params: {
              searchKey: 'code',
            },
          },
        })
          .get()
          .then((response) => {
            if (!response?.error && response?.data) {
              message.success({
                key: 'search-quiz',
                content: 'Game Found!',
              }, 2)
              setGame(response?.data)

            } else {
              message.error({
                key: 'search-quiz',
                content: 'Game not found!',
              }, 2)
            }
            setLoading(false)
          })
          .catch((err) => {
            setLoading(false)
          })

      })
  }

  let interval
  useEffect(() => {
    if (game) {
      let index = 3
      setInterval(() => {
        if (index === 0) {
          message.destroy('search-quiz')
          window.location.href = `/play/game/${game?.uuid}`
        } else {
          message.loading({
            key: 'search-quiz',
            content: `${index--} please wait ...`,
          }, 10000)
        }

      }, 1000)
    }
    return () => clearInterval(interval)
  }, [game])

  return auth?.user?.role?.slug === __ROLE__.STUDENT && (
    <React.Fragment>
      <Flex align={'center'}>
        <Button type={'primary'} onClick={() => setOpen(true)}>Play Quiz</Button>
      </Flex>
      <Modal
        title={'Search Quiz By Code'}
        closable={false}
        open={open}
        onOk={_onFinishCode}
        okButtonProps={{
          loading: loading,
        }}
        cancelButtonProps={{
          loading: loading,
        }}
        destroyOnClose
        onCancel={() => setOpen(false)}
      >
        <Form
          layout={'vertical'}
          form={FormQuizCode}
        >
          <Form.Item name={'code'} label={'Code'} rules={[{ required: true }]}>
            <Input placeholder={'Enter Code'} autoComplete={'off'} spellCheck={'false'} />
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default NavQuizCode