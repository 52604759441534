import { COLOR_1, COLOR_2, COLOR_3, COLOR_4, COLOR_5, COLOR_6, COLOR_7 } from '../../../../constants/ChartConstant'
import { DotNotation } from '../../../../utils/dot-notation'

export class Handler {

  static COLOR = [
    COLOR_1,
    COLOR_2,
    COLOR_3,
    COLOR_4,
    COLOR_5,
    COLOR_6,
    COLOR_7,
    COLOR_1,
    COLOR_2,
    COLOR_3,
    COLOR_4,
    COLOR_5,
    COLOR_6,
    COLOR_7,
  ]

  /**
   *
   * @param data
   * @param {object} options
   * @param {string} options.valueKey - using dot notation
   * @param {string} options.labelKey - using dot notation
   * @param {Array<string>} options.color
   * @return {{data: *[], color: *[], label: *[]}}
   */
  static chartDonut (data, options = {
    valueKey: 'value',
    labelKey: 'label',
    color: Handler.COLOR,
  }) {
    let _data = []
    let _label = []
    let _color = options?.color ?? Handler.COLOR
    if (Array.isArray(data) && data.length > 0) {
      data.map((child) => {
        _data.push(DotNotation.get(child, options?.valueKey))
        _label.push(DotNotation.get(child, options?.labelKey))
      })
    }
    return {
      data: _data,
      label: _label,
      color: _color,
    }
  }
}