import { Redirect, Route } from 'react-router-dom'
import { AUTH_PREFIX_PATH } from '../configs/AppConfig'
import LoadingAuthComponent from '../components/layout-components/loading-auth.component'
import PreloaderText from '../components/animated-components/preloader-text'
import { connect } from 'react-redux'
import React from 'react'

function RouteInterceptor ({ AuthPage, isAuth, loading, children: Component, ...rest }) {
  let { component: Children } = rest
  return (<Route
    {...({
      path: rest?.path, dispatch: rest?.dispatch, computedMatch: rest?.computedMatch,
    })}
    render={({ location }) => {

      return loading ? (<LoadingAuthComponent
        useContent
        mode={'light'}
        title={<PreloaderText loading dots={4} text={'Validating session'} />}
        message={'Mohon tunggu, sedang melakukan pengecekan session anda.'}
      />) : isAuth ? (typeof (AuthPage) !== 'undefined' ? <Redirect
        to={{
          pathname: '/', state: { from: location },
        }}
      /> : (Component || Children)) : (typeof (AuthPage) !== 'undefined' ? typeof (Component) !== 'undefined' ?
        <Component {...rest} /> : <Children {...rest} /> : <Redirect
        to={{
          pathname: '/auth/login', state: { from: location },
        }}
      />)
    }}
  />)
}

export default connect(({ auth }) => {
  let { isAuth, loading } = auth
  return { isAuth, loading }
})(RouteInterceptor)