import { FAILURE, REQUEST, SUCCESS } from '../actions/action.type'
import {
  ANALYTICS_DASHBOARD_GENERAL,
  GET_ANALYTICS_MISCELLANEOUS,
  GET_ANALYTICS_PARTICIPANTS,
  GET_ANALYTICS_PARTICIPANTS_USERS,
} from '../constants/Analytics'

const initialState = {
  participants: {
    loading: true,
    data: {
      data: [],
    },
    users: {
      loading: true,
      pagination: {
        page: 1,
        limit: 100,
      },
      data: [],
    },
  },
  miscellaneous: {
    other: {
      loading: true,
      label: [],
      data: [],
      options: {},
    },
  },
  dashboard: {
    collections: {
      general: {
        loading: true,
        data: [],
      },
    },
  },
}

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST(ANALYTICS_DASHBOARD_GENERAL):
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          collections: {
            ...state.dashboard.collections,
            general: {
              ...state.dashboard.collections.general,
              loading: true,
            },
          },
        },
      }
    case SUCCESS(ANALYTICS_DASHBOARD_GENERAL):
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          collections: {
            ...state.dashboard.collections,
            general: {
              ...state.dashboard.collections.general,
              loading: false,
              data: [...payload],
            },
          },
        },
      }
    case REQUEST(GET_ANALYTICS_PARTICIPANTS):
      return {
        ...state,
        participants: {
          ...state.participants,
          loading: true,
          data: {
            ...state.participants.data,
            data: [],
          },
        },
      }
    case SUCCESS(GET_ANALYTICS_PARTICIPANTS):
      if (Array.isArray(payload?.data) && payload?.data.length > 0) {

      }
      return {
        ...state,
        participants: {
          ...state.participants,
          loading: false,
          data: {
            ...state.participants.data,
            data: [...payload?.data],
          },
        },
      }
    case FAILURE(GET_ANALYTICS_PARTICIPANTS):
      return {
        ...state,
        participants: {
          ...state.participants,
          loading: true,
          data: {
            ...state.participants.data,
            data: [],
          },
        },
      }

    case REQUEST(GET_ANALYTICS_PARTICIPANTS_USERS):
      return {
        ...state,
        participants: {
          ...state.participants,
          users: {
            ...state.participants.users,
            loading: true,
            data: [],
          },
        },
      }
    case SUCCESS(GET_ANALYTICS_PARTICIPANTS_USERS):
      return {
        ...state,
        participants: {
          ...state.participants,
          loading: false,
          users: {
            ...state.participants.users,
            loading: false,
            data: payload?.data ?? [],
            pagination: {
              ...state.participants.users.pagination,
              ...payload?.pagination,
            },
          },
        },
      }
    case FAILURE(GET_ANALYTICS_PARTICIPANTS_USERS):
      return {
        ...state,
        participants: {
          ...state.participants,
          users: {
            ...state.participants.users,
            loading: false,
            data: [],
          },
        },
      }

    case REQUEST(GET_ANALYTICS_MISCELLANEOUS):
    default:
      return state
  }
}